import React from "react";
import Button from "@mui/material/Button";

function EnhancedConfirmButton(props) {
  const { handleOk, okLabel = "Potvrdit", hideOk, disabledOk = false } = props;

  return (
    <>
      <Button
        onClick={handleOk}
        color="primary"
        variant="contained"
        disabled={disabledOk}
        sx={hideOk ? buttonStyle.dNone : buttonStyle.ok}
      >
        {okLabel}
      </Button>
    </>
  );
}

const buttonStyle = {
  ok: {
    color: "white",
    background: "green",
    "&:hover": {
      color: "white",
      background: "#006400"
    }
  },
  dNone: {
    display: "none"
  }
};

export default EnhancedConfirmButton;
