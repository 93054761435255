import React from "react";
import {Divider, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {FiberManualRecord} from "@mui/icons-material";

function EnhancedAgenda(props) {
    const { searchResults, handleEventClick, classes, isAgendaView } = props;

    const handleClick = (event) => {
        handleEventClick({ event: event });
    };

    function getVisibility()
    {
        var display = 'none';
        if (isAgendaView) {
            display = 'inline';
        }

        return  {display: display}
    }

    return (
        <>
            <List sx={getVisibility}>
                {searchResults.map((event) => (
                    <React.Fragment key={event.id}>
                        <ListItem onClick={() => handleClick(event)}>
                            <ListItemIcon>
                                <FiberManualRecord style={{ color: event.backgroundColor }} />
                            </ListItemIcon>
                            <ListItemText primary={event.title} secondary={`${event.dateTimeText}`} />
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                ))}
            </List>
        </>
    );
}

export default EnhancedAgenda;
