import { Paper, TableCell } from "@mui/material";
import { makeStyles } from '@mui/styles';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanyList } from "../../features/listsSlice";
import EnhancedTable from "../enhanced/EnhancedTable";
import EnhancedTableToolbar from "../enhanced/EnhancedTableToolbar";

function CompanyTable() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const companyList = useSelector((state) => state.lists.companyList);

  useEffect(() => {
    dispatch(fetchCompanyList());
  }, [dispatch]);

  const headerList = [
    { align: "right", label: "ID" },
    { align: "right", label: "Název" },
    { align: "right", label: "Telefon" },
    { align: "right", label: "Email" },
    { align: "right", label: "Značka" },
    { align: "right", label: "Zkratka" }
  ];

  const renderRowCells = (company) => (
    <>
      <TableCell align="right">{company.id}</TableCell>
      <TableCell align="right">{company.name}</TableCell>
      <TableCell align="right">{company.phone}</TableCell>
      <TableCell align="right">{company.email}</TableCell>
      <TableCell align="right">{company.fileNumber}</TableCell>
      <TableCell align="right">{company.short}</TableCell>
    </>
  );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar authority="company.edit" label="Společnosti" />
        <EnhancedTable headerList={headerList} dataList={companyList} renderRowCells={renderRowCells} />
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  }
}));

export default CompanyTable;
