import React from "react";
import {ThemeProvider} from "@mui/material/styles";
import "bootstrap/dist/css/bootstrap.css";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import RouteCustom from "../app/RouteCustom";
import theme from "../theme";
import GrantedAuthHelper from "../helpers/GrantedAuthHelper";
import Dashboard from "../components/dashboard/Dashboard";
import Calendar from "../components/calendar/Calendar";
import CustomerPage from "../components/customer/Customer";
import Users from "../components/users/Users";
import Company from "../components/company/Company";
import Voucher from "../components/voucher/Voucher";
import PassLog from "../components/passlog/PassLog";
import AgendaList from "../components/agenda/Agenda";
import CleanerAgendaList from "../components/cleaner/Agenda";
import ReportForm from "../components/report/Report";
import AdvancedReportForm from "../components/advancedreport/AdvancedReport";
import CompanySumTable from "../components/companysum/CompanySum";
import Pricing from "../components/pricing/Pricing";
import PaymentPage from "../components/payment/Payment";
import NotPaidReservationList from "../components/notpaidreservation/Agenda";
import ImportAttendance from "../components/importatendence/ImportAttendance";
import SignIn from "../components/SignIn";
import SignUp from "../components/SignUp";
import NotFound from "../components/NotFound";
import MainLayout from "../components/layouts/main/Main";
import MinimalLayout from "../components/layouts/minimal/Minimal";
import Alert from "../components/Alert";
import UserRoleHelper from "../helpers/UserRoleHelper";
import AttendanceList from "../components/attenancelist/AttendanceList";
import HppInfo from "../components/hppinfo/HppInfo";
const App = () => {
  useSelector((state) => {
    GrantedAuthHelper.load(state.auth.role);
    UserRoleHelper.load(state.auth.main.role);

    return state.auth.role;
  });

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <RouteCustom component={Dashboard} layout={MainLayout} exact path="/dashboard" />
            <RouteCustom component={Calendar} layout={MainLayout} exact path="/calendar" />
            <RouteCustom component={CustomerPage} layout={MainLayout} exact path="/customer-management" />
            <RouteCustom component={Users} layout={MainLayout} exact path="/user-management" />
            <RouteCustom component={Company} layout={MainLayout} exact path="/company-management" />
            <RouteCustom component={Voucher} layout={MainLayout} exact path="/voucher-management" />
            <RouteCustom component={PassLog} layout={MainLayout} exact path="/pass-log-management" />
            <RouteCustom component={AgendaList} layout={MainLayout} exact path="/agenda" />
            <RouteCustom component={CleanerAgendaList} layout={MainLayout} exact path="/cleaner-agenda" />
            <RouteCustom component={ReportForm} layout={MainLayout} exact path="/report-management" />
            <RouteCustom component={AdvancedReportForm} layout={MainLayout} exact path="/adv-report-management" />
            <RouteCustom component={CompanySumTable} layout={MainLayout} exact path="/sum-management" />
            <RouteCustom component={Pricing} layout={MainLayout} exact path="/pricing-management" />
            <RouteCustom component={PaymentPage} layout={MainLayout} exact path="/payment-management" />
            <RouteCustom component={NotPaidReservationList} layout={MainLayout} exact path="/not-paid-reservations" />
            <RouteCustom component={ImportAttendance} layout={MainLayout} exact path="/import-attendance" />
            <RouteCustom component={AttendanceList} layout={MainLayout} exact path="/attendance-list" />
            <RouteCustom component={HppInfo} layout={MainLayout} exact path="/informace-o-prav-vztahu-zalozenem-na-hpp" />
            <RouteCustom component={SignIn} layout={MinimalLayout} exact path="/sign-in" />
            <RouteCustom component={SignUp} layout={MinimalLayout} exact path="/sign-up" />
            <RouteCustom component={NotFound} layout={MinimalLayout} exact path="/not-found" />
            <Redirect to="/not-found" />
          </Switch>
        </Router>
        <Alert />
      </ThemeProvider>
    </div>
  );
};

export default App;
