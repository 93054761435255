import React, { useState} from "react";
import {Grid, Paper} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useSelector} from "react-redux";
import CustomerSearchForm from "../customer/CustomerSearchForm";
import CustomerTable from "./CustomerTable";
import CustomerEditDialog from "./CustomerEditDialog";

function CustomerPage() {
  const classes = useStyles();
  const filteredCustomersList = useSelector((state) => state.modalEdit.filteredCustomersList);

  const customerViewConstants = { TABLE_VIEW: 0, FORM_VIEW: 1 };
  const [customerView, setCustomerView] = useState(customerViewConstants.FORM_VIEW);

  const [open, setOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});

  const [searchValue, setSearchValue] = useState("");

  const handleBackBtn = () => {
    setCustomerView(customerViewConstants.FORM_VIEW);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid>
            <CustomerEditDialog
                open={open}
                customer={selectedCustomer}
                setOpen={setOpen}
            />
            <CustomerSearchForm
                className={customerView === customerViewConstants.FORM_VIEW ? "" : classes.dNone}
                setCustomerView={setCustomerView}
                customerViewConstants={customerViewConstants}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
            <CustomerTable
                className={customerView === customerViewConstants.TABLE_VIEW ? "" : classes.dNone}
                filteredCustomersList={filteredCustomersList}
                handleBackBtn={handleBackBtn}
                open={open}
                customer={selectedCustomer}
                setOpen={setOpen}
                setSelectedCustomer={setSelectedCustomer}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        </Grid>
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        fontFamily: "Roboto, Helvetica, Arial, sans-serif !important"
    },
    dNone: {
        display: "none"
    },
    serachInput: { marginBottom: theme.spacing(2) }
}));

export default CustomerPage;
