import React, { useState } from "react";
import {Grid} from "@mui/material";
import Paper from "@mui/material/Paper";
import { makeStyles } from '@mui/styles';
import EnhancedTableToolbar from "../enhanced/EnhancedTableToolbar";
import {useDispatch} from "react-redux";
import EnhancedConfirmButton from "../enhanced/EnhancedConfirmButton";
import alertHelper from "../../helpers/alertHelper";
import FormControl from "@mui/material/FormControl";
import {addAttendance} from "../../features/importAttendanceSlice";

function ImportAttendance() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [formState, setFormState] = useState({});
  const [isSuccessImported, setSuccessImported] = useState(false);

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.files[0]
    }));
  };

  const handleClose = () => {
    setFormState({});
    setSuccessImported(true);
  };

  const handleSave = () => {
    const formData = new FormData();
    formData.append('file', formState.import)
    alertHelper.confirm({
      message: "Opravdu si přejete nahrát data?",
      callback: () => handleSaveConfirm(formData)
    });
    handleClose();
  };

  function handleSaveConfirm(formData) {
    dispatch(addAttendance(formData));

    alertHelper.info({
      "message": "Import dat z docházky byl úspěšně importován"
    });
  }

  return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar label="Import záznamů z docházkového systému Anviz" />
          <FormControl variant="outlined" className={classes.formControl}>
            <input
                name="import"
                id="faceImage"
                type="file"
                onChange={handleChange}
            />
          </FormControl>
          <Grid item xs={12}>
            <EnhancedConfirmButton handleOk={handleSave} okLabel="Nahrát" />
          </Grid>
        </Paper>
      </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(4),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  formControl: {
    width: "100%",
    padding: '5px'
  }
}));

export default ImportAttendance;
