import "@fortawesome/fontawesome-free/css/all.css";
import { FormControl, Grid, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { ArrowBack, Search } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { findReservationEvent } from "../../features/reservationSlice";
import EnhancedIconButton from "../enhanced/EnhancedIconButton";
import { useSelector } from "react-redux";

const SearchForm = (props) => {
  const dispatch = useDispatch();

  const { headerToolbarConf, headerToolbar, setHeaderToolbar, setEventView, eventViewConstants, isAgendaView } = props;
  const calendar = useSelector((state) => state.reservation.calendar);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    searchValue && dispatch(findReservationEvent(searchValue));
  }, [calendar, dispatch, searchValue]);

  const handleSearch = () => {
    dispatch(findReservationEvent(searchValue));
    setEventView(eventViewConstants.AGENDA_VIEW);
  };

  const handleBackBtn = () => {
    setHeaderToolbar(headerToolbarConf);
    setEventView(eventViewConstants.CALENDAR_VIEW);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") handleSearch();
  };

  function getSearchFormStyle(headerToolbar)
  {
    if (headerToolbar === false) {
      return styles.searchInput;
    }

    var display = styles.none;
    if (isAgendaView) {
      display = 'inline';
    }

    return  {display: display}
  }

  return (
    <Grid sx={getSearchFormStyle} container spacing={1}>
      <Grid item xs={6}>
        <EnhancedIconButton ariaLabel="back icon" tooltip="Zpět" Icon={ArrowBack} onClick={handleBackBtn} />
      </Grid>
      <Grid item xs={12}>
        <FormControl onKeyDown={(e) => handleKeyDown(e)} fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-amount">Search</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <EnhancedIconButton ariaLabel="back icon" tooltip="Zpět" Icon={Search} onClick={handleSearch} />
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

const styles = {
  root: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important"
  },
  none: {
    display: "none"
  },
  searchInput: { marginBottom: "1em" }
};

export default SearchForm;
