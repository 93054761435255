import React, { useEffect, useState } from "react";
import {Button, CircularProgress, Container, TextField} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import {makeStyles, styled} from '@mui/styles';
import Moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import EnhancedButton from "../../enhanced/EnhancedButton";
import EnhancedDialogTitle from "../../enhanced/EnhancedDialogTitle";
import EnhancedTextField from "../../enhanced/EnhancedTextField";
import { applyTicketAsync } from "../../../features/applySlice";
import { fetchMasseurs, fetchReceptionists } from "../../../features/listsSlice";
import { exportBillPdf, payBill } from "../../../features/paymentSlice";
import {
  customerIsNotComeAsync,
  deleteEventByAsync,
  editHourEventAsync,
  cancelPayment,
  IDLE,
  LOADING,
  SUCCESS
} from "../../../features/reservationSlice";
import alertHelper from "../../../helpers/alertHelper";
import GrantedAuthHelper from "../../../helpers/GrantedAuthHelper";
import PaymentTypeResolver from "../../../helpers/paymentTypeResolver";
import { getSessionCheckedCompany } from "../../../helpers/sessionHelper";
import EnhancedDateField from "../../enhanced/EnhancedDateField";
import HMCustomerSection from "./HMCustomerSection";
import HMStartEndSection from "./HMStartEndSection";
import HMPaymentSection from "./HMPaymentSection";
import EnhancedSelect from "../../enhanced/EnhancedSelect";
import {getDBDateFromMomentObject} from "../../../helpers/DateConvertor";
import ReservationDateValidator from "../../../helpers/ReservationDateValidator";
import {reservationValidator} from "../../../helpers/validator/reservationValidator";

function HourModal(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const status = useSelector((state) => state.reservation.status);
  const dataById = useSelector((state) => state.reservation.dataById);
  const masseurList = useSelector((state) => state.lists.masseurList);
  const receptionistList = useSelector((state) => state.lists.receptionistList);

  const { open, setOpen, selected, setSelected } = props;

  const [formState, setFormState] = useState({});
  const [startAtWork, setStart] = useState();
  const [endAtWork, setEnd] = useState();

  useEffect(() => {
    if (dataById[selected]) {
      setFormState((formState) => ({
        ...formState,
        room: dataById[selected]?.room,
        note: dataById[selected]?.note,
        title: dataById[selected]?.title,
        masseurId: dataById[selected]?.masseur?.id,
        receptionistId: dataById[selected]?.receptionist?.id,
        isCome: dataById[selected]?.isCome,
        slevomatDate: dataById[selected]?.slevomatDate ? new Moment(dataById[selected]?.slevomatDate) : null,
        length: dataById[selected]?.length
      }));

      let notPaidOwnReservations = dataById[selected]?.notPaid;
      if (notPaidOwnReservations.count > 0) {
        alertHelper.error({
          message: `Počet neuzavřených rezervací: ${notPaidOwnReservations.count} ( ${notPaidOwnReservations.message} )`
        });
      }
    }
  }, [dataById, selected]);

  useEffect(() => {
    if (open && getSessionCheckedCompany()) {
      dispatch(fetchMasseurs());
      dispatch(fetchReceptionists());
    }
  }, [open, dispatch]);

  function isPassLog() {
    return PaymentTypeResolver.isPassLog(formState.massageTypeShort);
  }

  function isVoucher() {
    return PaymentTypeResolver.isVoucher(formState.massageTypeShort);
  }

  function isSlevomat() {
    return PaymentTypeResolver.isSlevomat(formState.paymentTypeId);
  }

  function isCash() {
    return PaymentTypeResolver.isCash(formState.paymentTypeId);
  }

  function isCreditCard() {
    return PaymentTypeResolver.isCreditCard(formState.paymentTypeId);
  }

  function isDisabledButtons() {
    let thirtyMinutesAfter = Moment(endAtWork);
    thirtyMinutesAfter.set({
      minute: thirtyMinutesAfter.minute() + 30
    });

    return thirtyMinutesAfter < Moment() && formState.voucherApplied && !GrantedAuthHelper.hasAuthority("all");
  }

  function handleChange(event) {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  }

  const handleChangeSlevomatDate = (event) => {
    setFormState((formState) => ({
      ...formState,
      ['slevomatDate']: event
    }));
  };

  function handleClose() {
    setSelected(null);
    setOpen(false);
    setFormState({});
  }

  function handleDelete() {
    dispatch(deleteEventByAsync(selected));
    handleClose();
  }

  function handleCancelPayment() {
    let apiData = {
      reservationId: dataById[selected].id
    };

    alertHelper.confirm({
      message: "Opravdu si přejete zrušit platbu?",
      callback: () => dispatch(cancelPayment(apiData))
    });
  }

  function saveReservation(start, end) {
    const apiData = {
      id: dataById[selected].id,
      title: formState.title,
      start: start,
      end: end,
      note: formState.note,
      masseurId: formState.masseurId,
      receptionistId: formState.receptionistId,
      customerId: formState.customer?.id,
      roomId: formState.room?.id,
      paymentId: formState.paymentId
    };

    dispatch(editHourEventAsync(apiData));
  }

  function handleOk() {

    if (reservationValidator(formState, startAtWork, endAtWork)) {
      saveReservation(startAtWork, endAtWork)
      handleClose();
    }
  }

  function handlePay() {
    const apiData = {
      companyId: getSessionCheckedCompany()
    };

    let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (format.test(formState.voucherNumber))
    {
      alertHelper.error({
        message: 'V čísle poukazu jsou speciální znaky!',
        duration: 7000
      });

      return false;
    }

    if (isSlevomat() && !formState.voucherNumber) {
      alertHelper.error({
        message: 'Není vyplněno číslo poukazu (v čísle poukazu)',
        duration: 7000
      });

      return false;
    }

    if (formState.title.length > 99) {
      alertHelper.error({ message: "Titulek nesmí být delší než 100 znaků!" });
      return false;
    }

    apiData.paymentTypeId = formState.paymentTypeId;
    apiData.voucherNumber = formState.voucherNumber;
    apiData.massageTypeId = formState.typeOfMassageId;
    apiData.billUUID = formState.billUUID;
    apiData.invoiceAddress = formState.invoiceAddress;

    let exportBill = isCash() || isCreditCard();
    alertHelper.confirm({
      message: getConfirmPayMessage(),
      callback: () => payAndSave(apiData, exportBill),
      className: isPayInTheFuture() ? classes.confirmWarning: null
    });
  }

  function isPayInTheFuture() {
    let startTime = new Moment(startAtWork);
    startTime.set({
      minute: startTime.minute() - 30
    });

    return startTime > Moment();
  }

  function getConfirmPayMessage() {
    let confirmMessage = 'Opravdu si přejete zaplatit rezervaci?';
    if (isPayInTheFuture()) {
      confirmMessage = 'POZOR: Opravdu si přejete zaplatit rezervaci, která ještě neproběhla?';
    }

    return confirmMessage;
  }

  function payAndSave(apiData, exportBill) {
    const reservationData = {
      id: dataById[selected].id,
      title: formState.title,
      start: dataById[selected].start,
      end: dataById[selected].end,
      note: formState.note,
      masseurId: formState.masseurId,
      receptionistId: formState.receptionistId,
      customerId: formState.customer?.id,
      roomId: formState.room?.id
    };

    dispatch(payBill({ apiData, exportBill, reservationData }));
  }

  function handleApply() {

    if (!reservationValidator(formState, startAtWork, endAtWork)) {
      return false;
    }

    saveReservation(dataById[selected].start, dataById[selected].end);
    const apiData = {
      voucherNumber: formState.voucherNumber,
      reservationId: dataById[selected].id,
    };


    if (isVoucher() || isPassLog()) {
      let reservationDateObj = new Moment(startAtWork);
      apiData.applyDate = getDBDateFromMomentObject(reservationDateObj);
    }

    if (isSlevomat()) {
      if (formState.slevomatDate === null) {
        alertHelper.error({ message: "Musíte zadat datum uplatnění!" });
        return false;
      }

      apiData.applyDate = getDBDateFromMomentObject(formState.slevomatDate);
    }

    dispatch(applyTicketAsync(apiData));

  }

  function handleNotTakePlace() {
    alertHelper.confirm({
      message: "Opravdu masáž v tento termín neproběhla?",
      callback: () => dispatch(customerIsNotComeAsync(dataById[selected].id))
    });
  }

  function handleExportBill() {
    if (formState.billUUID) {
      dispatch(exportBillPdf(formState.billUUID));
    }
  }

  function isOkDisabled() {
    return isDisabledButtons();
  }

  function isPayDisabled() {
    return !formState.paymentTypeId || !formState.typeOfMassageId;
  }

  function isApplyDisabled() {
    return (!isPassLog() && !isVoucher() && !(formState.paymentId && isSlevomat())) || !!formState.voucherApplied;
  }

  function isDeleteDisabled() {
    return !selected || !!formState.paymentId || isDisabledButtons();
  }

  function isCancelPaymentDisabled() {
    if (isPassLog() || isVoucher() || isSlevomat()) {
      return !formState.voucherApplied;
    }

    return !formState.paymentId;
  }

  function isNotTakePlaceDisabled() {
    return (
      ((isPassLog() || isVoucher()) && !!formState.voucherApplied) ||
      (!isPassLog() && !isVoucher() && !!formState.paymentId) ||
      isDisabledButtons()
    );
  }

  function isExportBillDisabled() {
    return !formState.paymentId || (formState.paymentId && !isCash() && !isCreditCard());
  }

  function isDisabledSlevomatDate() {
    return isApplyDisabled() || !isSlevomat();
  }

  function isDisabledDateTime() {
    return (isApplyDisabled() || formState.paymentId) && (!GrantedAuthHelper.hasAuthority("all") && !!formState.paymentId);
  }

  function isCancelPaymentVisible() {
    return GrantedAuthHelper.hasAuthority("all");
  }



  function getDialogContent() {
    if (status === LOADING) {
      return (
        <div style={{ height: "600px" }}>
          <CircularProgress className={classes.progress} />
        </div>
      );
    }

    if (status === IDLE) {
      return null;
    }

    if (status === SUCCESS)
      return (
        <Grid container spacing={3} sx={{marginTop: -2}}>
          <Grid item xs={12}>
            <EnhancedTextField
                name="title"
                value={formState.title}
                label="Název"
                onChange={handleChange}
                maxLength="90"
            />
          </Grid>
          <HMCustomerSection
            selected={selected}
            formState={formState}
            setFormState={setFormState}
            handleChange={handleChange}
          />
          <Grid item xs={4}>
            <EnhancedTextField
              disabled={true}
              name="room"
              value={formState.room?.name}
              label="Room"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <EnhancedSelect
                name="receptionistId"
                value={formState.receptionistId}
                label="Recepční"
                list={receptionistList}
                onChange={handleChange}
                itemName="fullName"
            />
          </Grid>
          <Grid item xs={4}>
            <EnhancedSelect
                name="masseurId"
                value={formState.masseurId}
                label="Masér"
                list={masseurList}
                onChange={handleChange}
                itemName="fullName"
            />
          </Grid>
          <HMStartEndSection
            formState={formState}
            setFormState={setFormState}
            handleChange={handleChange}
            setStart={setStart}
            setEnd={setEnd}
            selected={selected}
            open={open}
            disabled={isDisabledDateTime()}
          />
          <Grid item xs={12}>
            <EnhancedTextField name="note" value={formState.note} label="Poznámka" onChange={handleChange} rows={5} multiline={true} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <HMPaymentSection
            open={open}
            selected={selected}
            formState={formState}
            setFormState={setFormState}
            handleChange={handleChange}
          />
          <Grid item xs={12}>
            <EnhancedDateField
              disabled={isDisabledSlevomatDate()}
              name="slevomatDate"
              label="Slevomat - datum uplatnění"
              value={formState.slevomatDate}
              handleChange={handleChangeSlevomatDate}
            />
          </Grid>
        </Grid>

      );
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Rezervace" />
        <DialogContent>{getDialogContent()}</DialogContent>
        <DialogActions>
          <EnhancedButton
            onClick={handleDelete}
            disabled={isDeleteDisabled()}
            className={classes.delete}
            label="Smazat"
            authority="calendar.delete"
            sx={buttonStyles.delete}
          />

          {isCancelPaymentVisible() ? (
              <EnhancedButton
                  onClick={handleCancelPayment}
                  disabled={isCancelPaymentDisabled()}
                  className={classes.cancel}
                  label="Magic"
                  authority="payment.cancel-payment"
                  sx={buttonStyles.cancel}
              />
          ) : null}
          <EnhancedButton
            onClick={handleNotTakePlace}
            disabled={isNotTakePlaceDisabled()}
            className={classes.notTakePlace}
            label="Neobsazeno"
            authority="calendar.edit"
            sx={buttonStyles.notTakePlace}
          />
          <EnhancedButton
            onClick={handlePay}
            disabled={isPayDisabled() || !!formState.paymentId}
            className={classes.pay}
            label="Zaplatit"
            authority="calendar.edit"
            sx={buttonStyles.pay}
          />
          <EnhancedButton
            onClick={handleExportBill}
            disabled={isExportBillDisabled()}
            className={classes.exportBill}
            label="Účtenka"
            authority="calendar.edit"
            sx={buttonStyles.exportBill}
          />
          <EnhancedButton
            onClick={handleApply}
            disabled={isApplyDisabled()}
            className={classes.apply}
            label="Uplatnit"
            authority="calendar.edit"
            sx={buttonStyles.apply}
          />
          <EnhancedButton
            onClick={handleOk}
            disabled={isOkDisabled()}
            className={classes.ok}
            label="Potvrdit"
            authority="calendar.edit"
            sx={buttonStyles.ok}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const buttonStyles = {
  ok: {
    color: "white",
    background: "green",
    "&:hover": {
      color: "white",
      background: "#006400"
    }
  },
  pay: {
    color: "white",
    background: "blue",
    "&:hover": {
      color: "white",
      background: "darkblue"
    }
  },
  apply: {
    color: "white",
    background: "#ff6600",
    "&:hover": {
      color: "white",
      background: "#cc5200"
    }
  },
  delete: {
    color: "white",
    background: "red",
    "&:hover": {
      color: "white",
      background: "#B22222"
    }
  },
  notTakePlace: {
    color: "white",
    background: "#cc00cc",
    "&:hover": {
      color: "white",
      background: "#660066"
    }
  },
  exportBill: {
    color: "white",
    background: "#33cccc",
    "&:hover": {
      color: "white",
      background: "#29a3a3"
    }
  },
  cancel: {
    color: "white",
    background: "#ff9900",
    "&:hover": {
      color: "white",
      background: "#cc7a00"
    }
  },
}


const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2)
  },
  root: {
    flexGrow: 1
  },
  formControl: {
    width: "100%"
  },
  confirmWarning: {
    color: "#ff3300"
  },
}));

export default HourModal;
