import { Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import { makeStyles } from '@mui/styles';
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import EnhancedConfirmButton from "../enhanced/EnhancedConfirmButton";
import EnhancedTextField from "../enhanced/EnhancedTextField";
import { insertCompanySums } from "../../features/companySumSlice";
import { fetchAllowedCompanyList } from "../../features/listsSlice";
import EnhancedDialogTitle from "../enhanced/EnhancedDialogTitle";
import alertHelper from "../../helpers/alertHelper";
import EnhancedDateField from "../enhanced/EnhancedDateField";
import EnhancedSelect from "../enhanced/EnhancedSelect";
import UserRoleHelper from "../../helpers/UserRoleHelper";
import {getDBDateFromMomentObject} from "../../helpers/DateConvertor";

function NewCompanySumDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { open, setOpenNew } = props;
  const [formState, setFormState] = useState({});

  const allowedCompanyList = useSelector((state) => state.lists.allowedCompanyList);

  useEffect(() => {
    open && dispatch(fetchAllowedCompanyList());
  }, [open, dispatch]);

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const handleChangeValiditySince = (event) => {
    setFormState((formState) => ({
      ...formState,
      ['validitySince']: event
    }));
  };

  const handleChangeValidityTo = (event) => {
    setFormState((formState) => ({
      ...formState,
      ['validityTo']: event
    }));
  };

  const handleClose = () => {
    setOpenNew(false);
    setFormState({});
  };

  const handleSave = () => {
    const apiData = {
      companyId: formState.companyId,
      validitySince: getDBDateFromMomentObject(formState.validitySince),
      validityTo: getDBDateFromMomentObject(formState.validityTo),
      sumUpSum: formState.sumUpSum,
      slevomatSum: formState.slevomatSum,
    };

    if (!formState.companyId) {
      alertHelper.error({ message: "Musíte zadat organizaci!" });
      return false;
    }

    if (!formState.validitySince || !formState.validityTo) {
      alertHelper.error({ message: "Musíte zadat datum platnosti od do!" });
      return false;
    }

    if (!formState.sumUpSum || !formState.slevomatSum) {
      alertHelper.error({ message: "Musíte zadat sumarizační hodnoty!" });
      return false;
    }

    dispatch(insertCompanySums(apiData))
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="form-dialog-title">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Vložit sumarizační hodnoty za organizaci" />
        <DialogContent>
          <Grid container spacing={3} sx={{marginTop: -2}}>
            <Grid item xs={12}>
              <EnhancedSelect
                  name="companyId"
                  value={formState.companyId}
                  label="Organizace"
                  list={allowedCompanyList}
                  itemId="companyId"
                  itemName="companyName"
                  onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedDateField
                  name="validitySince"
                  value={formState.validitySince}
                  label="Platnost od"
                  onChange={handleChangeValiditySince}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedDateField
                  name="validityTo"
                  value={formState.validityTo}
                  label="Platnost do"
                  onChange={handleChangeValidityTo}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField
                  name="sumUpSum"
                  value={formState.sumUpSum}
                  label="SumUp hodnota"
                  onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField
                  name="slevomatSum"
                  value={formState.slevomatSum}
                  label="Počet uplatněných poukazů"
                  onChange={handleChange}
              />
            </Grid>
            {UserRoleHelper.isSpravce() || UserRoleHelper.isAdmin() ? (
                <Grid item xs={12}>
                  <EnhancedTextField
                      name="fakePrice"
                      value={formState.fakePrice}
                      label="Korekce"
                      onChange={handleChange}
                  />
                </Grid>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <EnhancedConfirmButton handleOk={handleSave} okLabel="Vložit" />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}));

export default NewCompanySumDialog;
