class GrantedAuthHelper {
  constructor() {
    this.authorities = [];
    this.wildcards = [];
  }

  load(role) {
    Object.keys(role).forEach((pageName) => {
      role[pageName].forEach((privilegeObj) => {
        Object.keys(privilegeObj).forEach((privilegeName) => {
          const authName = `${pageName}.${privilegeName}`;
          if (authName.indexOf("all") > -1) {
            const pattern = authName.replace(/all/g, "[a-z_]+") + "$";
            this.wildcards.push(new RegExp(pattern));
          } else {
            this.authorities.push(authName);
          }
        });
      });
    });
  }

  empty() {
    this.authorities = [];
    this.wildcards = [];
  }

  hasAuthority(authority) {
    if (this.authorities.indexOf(authority) > -1) {
      return true;
    } else {
      for (let i = 0; i < this.wildcards.length; i++) {
        const wildcard = this.wildcards[i];
        if (wildcard.test(authority)) {
          return true;
        }
      }

      return false;
    }
  }
}

export default new GrantedAuthHelper();
