import { Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {editUserSpecial, insertAttendanceUserDay, requestUserSpecialProfileBy} from "../../features/usersSlice";
import EnhancedConfirmButton from "../enhanced/EnhancedConfirmButton";
import EnhancedTextField from "../enhanced/EnhancedTextField";
import EnhancedDialogTitle from "../enhanced/EnhancedDialogTitle";
import EnhancedSelect from "../enhanced/EnhancedSelect";
import EnhancedDateField from "../enhanced/EnhancedDateField";
import Moment from "moment";
import {getDBDateFromMomentObject} from "../../helpers/DateConvertor";

function UserAddAttendanceWorkDayDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { open, setOpen, user } = props;
  const [formState, setFormState] = useState({});

  const [startDate] = useState(new Moment());
  const [startEnd] = useState(new Moment().add(1, "months"));

  let workingDayList = [
    { id: 1, name: "Pondělí" },
    { id: 2, name: "Úterý" },
    { id: 3, name: "Středa" },
    { id: 4, name: "Čtvrtek" },
    { id: 5, name: "Pátek" },
    { id: 6, name: "Sobota" },
    { id: 7, name: "Neděle" },
  ];

  function handleChange(event) {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  }

  const handleChangeExpireDate = (event) => {
    setFormState((formState) => ({
      ...formState,
      ['dateExpire']: event
    }));
  };

  function handleClose() {
    setOpen(false);
    setFormState({});
  }

  function handleOk() {
    const apiData = {};
    apiData.userId = user.id;
    apiData.dayOfWeek = formState.dayOfWeek;
    apiData.timeCount = formState.timeCount;
    apiData.dateSince = getDBDateFromMomentObject(startDate);
    apiData.dateExpire = getDBDateFromMomentObject(formState.dateExpire || startEnd);

    dispatch(insertAttendanceUserDay(apiData));
    handleClose();
  }

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="form-dialog-title">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Přidání pracovního dne uživatele" />
        <DialogContent>
          <Grid container spacing={3} sx={{marginTop: -2}}>
            <Grid item xs={6}>
              <EnhancedSelect
                  name="dayOfWeek"
                  value={formState.dayOfWeek}
                  label="Pracovní den"
                  list={workingDayList}
                  onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField
                  name="timeCount"
                  value={formState.timeCount}
                  label="Počet hodin"
                  onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedDateField
                  name="dateSince"
                  label="Platnost od"
                  value={startDate}
                  disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedDateField
                  name="dateExpire"
                  label="Platnost do"
                  value={formState.dateExpire || startEnd}
                  handleChange={handleChangeExpireDate}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <EnhancedConfirmButton handleClose={handleClose} handleOk={handleOk} />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}));

export default UserAddAttendanceWorkDayDialog;
