import React from "react";
import { makeStyles } from '@mui/styles';
import PropTypes from "prop-types";
import useErrorHandler from "../../../helpers/useErrorHandler";
import Topbar from "../minimal/Topbar";

const Minimal = (props) => {
  const { children } = props;
  const classes = useStyles();
  useErrorHandler();

  return (
    <div className={classes.root}>
      <Topbar />
      <main className={classes.content}>{children}</main>
    </div>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 64,
    height: "100%"
  },
  content: {
    height: "100%"
  }
}));

export default Minimal;
