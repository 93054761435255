class PaymentTypeResolver {
  isVoucher(paymentTypeShort) {
    let voucherShorts = ["SDH", "NDH", "SDK", "NDK", "DFAV", 'RDH', 'RDK'];
    return voucherShorts.indexOf(paymentTypeShort) !== -1;
  }

  isPassLog(paymentTypeShort) {
    let passLogShorts = ["SPH", "NPH", "SPK", "NPK", "PFAV"];
    return passLogShorts.indexOf(paymentTypeShort) !== -1;
  }

  isBill(paymentTypeId) {
    return parseInt(paymentTypeId) === 4;
  }

  isSlevomat(paymentTypeId) {
    return parseInt(paymentTypeId) === 3;
  }

  isCash(paymentTypeId) {
    return parseInt(paymentTypeId) === 1;
  }

  isCreditCard(paymentTypeId) {
    return parseInt(paymentTypeId) === 2;
  }
}

export default new PaymentTypeResolver();
