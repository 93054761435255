import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getFetchURL } from "../helpers/actionHelpers";

const initialState = {
  attendanceUserList: []
};

export const fetchUserAttendanceList = createAsyncThunk(
  "attendanceList/fetchUserAttendanceList",
  async (apiData, { dispatch }) => {
    const response = await axios.get(
      getFetchURL(`/attendance/list/get/${apiData.userId}/${apiData.dateSince}/${apiData.dateTo}`)
    );

    return response.data;
  }
);

export const editUserAttendanceList = createAsyncThunk(
  "attendanceList/editUserAttendanceList",
  async (apiData, { dispatch }) => {
    const options = {
      method: "PUT",
      data: apiData,
      headers: { "Content-Type": "application/json" },
      url: getFetchURL("/attendance/list/edit")
    };

    const response = await axios(options);
    return response.data;
  }
);


export const attendanceListSlice = createSlice({
  name: "attendanceList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserAttendanceList.fulfilled, (state, action) => {
        state.attendanceUserList = action.payload;
      })
      .addCase(editUserAttendanceList.fulfilled, (state, action) => {
        const indexOfElement = state.attendanceUserList.findIndex(obj=>obj.id === action.payload.id);
        state.attendanceUserList[indexOfElement] = action.payload;
      })
  },
});

export default attendanceListSlice.reducer;
