import React, {useState} from "react";
import { makeStyles } from '@mui/styles';
import { useSelector} from "react-redux";
import AttendanceListForm from "./AttendanceListForm";
import AttendanceListFoundData from "./AttendanceListFoundData";

function AttendanceList() {
  const userList = useSelector((state) => state.users.userList);
  const attendanceUserList = useSelector((state) => state.attendanceList.attendanceUserList);
  const eventViewConstants = { ATTENDANCE_FORM_VIEW: 0, ATTENDANCE_TABLE_VIEW: 1 };
  const [eventView, setEventView] = useState(eventViewConstants.ATTENDANCE_FORM_VIEW);
  const classes = useStyles();

  return (
      <div className={classes.root}>
          <span className={eventView === eventViewConstants.ATTENDANCE_FORM_VIEW ? "" : classes.dNone}>
            <AttendanceListForm
              userList={userList}
              setEventView={setEventView}
              eventViewConstants={eventViewConstants}
            />
          </span>
          <span className={eventView === eventViewConstants.ATTENDANCE_TABLE_VIEW ? "" : classes.dNone}>
            <AttendanceListFoundData
                attendanceUserList={attendanceUserList}
                setEventView={setEventView}
                eventViewConstants={eventViewConstants}
            />
          </span>
      </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(4),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  formControl: {
    padding: '5px'
  },
  dNone: {
    display: "none"
  }
}));

export default AttendanceList;
