import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from '@mui/styles';
import { format, setHours } from "date-fns";
import { useSelector } from "react-redux";
import alertHelper from "../../../helpers/alertHelper";
import {DateTimePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns"
import {Container} from "@mui/material";
import cs from 'date-fns/locale/cs';

function HMStartEndSection(props) {
  const classes = useStyles();
  const dataById = useSelector((state) => state.reservation.dataById);

  const { selected, setStart, setEnd, setFormState, formState, open, disabled, date } = props;

  useEffect(() => {
    if (open && date) {
      setStartEndAtWorkAfterChange(formatTime(date));
    }
  }, [open, date]);


  useEffect(() => {
    if (open && dataById?.[selected]) {
      setFormState((formState) => ({
        ...formState,
        startAtWork: formatTime(dataById?.[selected]?.start),
        endAtWork: formatTime(dataById?.[selected]?.end),
      }));

      distributeValue(dataById?.[selected]?.start, dataById?.[selected]?.end);
    }
  }, [open, dataById, selected, setFormState]);

  function setStartEndAtWorkAfterChange(date) {
    let dateStr = date;
    let hour = parseInt(format(date, 'HH'));
    let dateStrHr = setHours(date, hour+1);

    setFormState((formState) => ({
      ...formState,
      startAtWork: dateStr,
      endAtWork: dateStrHr,
      paymentTypeId: null,
      typeOfMassageId: null
    }));

    distributeValue(dateStr, dateStrHr);
  }

  function formatTime(dateTime) {
    if (!dateTime) {
      return "";
    }

    return new Date(dateTime);
  }

  function formatDateTime(dateTime) {
    if (!dateTime) {
      return "";
    }

    return format(dateTime, 'yyyy-MM-dd HH:mm:ss');
  }

  function handleBlur(e) {
    distributeValue(formState.startAtWork, formState.endAtWork);
  }

  function handleChangeStartDate(date) {
    setStartEndAtWorkAfterChange(date);
  }

  function handleChangeEndDate(date) {
    setFormState((formState) => ({
      ...formState,
      endAtWork: date,
      paymentTypeId: null,
      typeOfMassageId: null

    }));

    distributeValue(formState.startAtWork, date);
  }

  function distributeValue(start, end) {
    let startObj = start;
    let endObj = end;
    if (typeof start == 'string') {
      startObj = new Date(start);
    }

    if (typeof start == 'string') {
      endObj = new Date(end);
    }

    let startAtWork;
    let endAtWork;
    let maxLongMassage = 120;

    if (startObj > endObj || (endObj - startObj) > (maxLongMassage * 60 * 1000)) {
      alertHelper.error({ message: "Zadané datum není validní" });
    } else {
      startAtWork = formatDateTime(startObj);
      endAtWork = formatDateTime(endObj);
    }

    setStart(startAtWork);
    setEnd(endAtWork);
  }
  
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}  adapterLocale={cs}>
        <Container components={['DateTimePicker', 'DateTimePicker']}>
          <Grid container spacing={2} sx={{marginTop: 1, marginLeft: -1.5}}>
            <Grid item xs={6}>
              <DateTimePicker
                  disabled={disabled}
                  name="startAtWork"
                  value={formState.startAtWork || null}
                  label="Začátek"
                  onChange={handleChangeStartDate}
                  onBlur={handleBlur}
                  className={classes.textField}
                  inputVariant="outlined"
                  format="d.M.y H:m"
                  autoOk
                  ampm={false}
              />
            </Grid>
            <Grid item xs={6}>
              <DateTimePicker
                  disabled={disabled}
                  name="endAtWork"
                  value={formState.endAtWork || null}
                  label="Konec"
                  onChange={handleChangeEndDate}
                  onBlur={handleBlur}
                  className={classes.textField}
                  inputVariant="outlined"
                  format="d.M.y H:m"
                  ampm={false}
              />
            </Grid>
          </Grid>
        </Container>
      </LocalizationProvider>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  textField: {
    width: 260
  }
}));

export default HMStartEndSection;
