import { configureStore } from "@reduxjs/toolkit";
import applyTicketReducer from "../features/applySlice";
import authReducer from "../features/authSlice";
import companySumReducer from "../features/companySumSlice";
import dailyNoteReducer from "../features/dailyNoteSlice";
import listsReducer from "../features/listsSlice";
import modalEditReducer from "../features/modalEditSlice";
import passLogReducer from "../features/passLogSlice";
import paymentReducer from "../features/paymentSlice";
import reservationReducer from "../features/reservationSlice";
import usersReducer from "../features/usersSlice";
import voucherReducer from "../features/voucherSlice";
import pricingSlice from "../features/pricingSlice";
import importAttendanceSlice from "../features/importAttendanceSlice";
import attendanceListSlice from "../features/attendanceListSlice";
import settingsSlice from "../features/settingsSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    companySum: companySumReducer,
    dailyNote: dailyNoteReducer,
    reservation: reservationReducer,
    lists: listsReducer,
    modalEdit: modalEditReducer,
    applyTicket: applyTicketReducer,
    payment: paymentReducer,
    passLog: passLogReducer,
    voucher: voucherReducer,
    users: usersReducer,
    pricing: pricingSlice,
    importAttendance: importAttendanceSlice,
    attendanceList: attendanceListSlice,
    settingsSlice: settingsSlice
  }
});
