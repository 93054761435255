import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getFetchURL } from "../helpers/actionHelpers";

const initialState = { dataById: {} };

export const addAttendance = createAsyncThunk("importAttendance/addAttendance", async (formData, { dispatch }) => {
  const options = {
    method: "POST",
    data: formData,
    headers: {
      'content-type': 'multipart/form-data'
    },
    url: getFetchURL("/attendance/import")
  };

  const response = await axios(options);
  return response.data;
});

export const importAttendanceSlice = createSlice({
  name: "importAttendance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addAttendance.fulfilled, (state, action) => {

    });
  }
});

export default importAttendanceSlice.reducer;
