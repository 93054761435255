import { Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editUserSpecial, requestUserSpecialProfileBy } from "../../features/usersSlice";
import EnhancedConfirmButton from "../enhanced/EnhancedConfirmButton";
import EnhancedTextField from "../enhanced/EnhancedTextField";
import EnhancedDialogTitle from "../enhanced/EnhancedDialogTitle";

function UserDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const userSpecialProfile = useSelector((state) => state.users.userSpecialProfile);
  const userProfileTypeList = useSelector((state) => state.users.userProfileTypeList);

  const { open, setOpen, user } = props;
  const [formState, setFormState] = useState({});

  useEffect(() => {
    open && dispatch(requestUserSpecialProfileBy(user.id));
  }, [open, user, dispatch]);

  useEffect(() => {
    userProfileTypeList.forEach((p) => {
      setFormState((formState) => ({
        ...formState,
        [p.id]: userSpecialProfile?.options?.[p.id]?.value
      }));
    });
  }, [userProfileTypeList, userSpecialProfile]);

  function handleChange(event) {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  }

  function handleClose() {
    setOpen(false);
    setFormState({});
  }

  function handleOk() {
    const apiData = {};
    apiData.userId = user.id;
    apiData.options = {};

    userProfileTypeList.forEach((p) => {
      apiData.options[p.id] = {
        id: userSpecialProfile?.options?.[p.id]?.id,
        value: formState[p.id]
      };
    });

    dispatch(editUserSpecial(apiData));
    handleClose();
  }

  if (!userSpecialProfile.options) return null;

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="form-dialog-title">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Profilové údaje uživatele" />
        <DialogContent>
          <Grid container spacing={3} sx={{marginTop: -2}}>
            {userProfileTypeList.map((p) => (
              <Grid key={p.id} item xs={6}>
                <EnhancedTextField name={String(p.id)} value={formState[p.id]} label={p.name} onChange={handleChange} />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <EnhancedConfirmButton handleClose={handleClose} handleOk={handleOk} />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}));

export default UserDialog;
