import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import store from "./app/configureStore";
import * as serviceWorker from "./serviceWorker";
import App from "./app/App";

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
