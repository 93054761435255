import React from "react";
import GrantedAuthHelper from "../../helpers/GrantedAuthHelper";
import EnhancedIconButton from "./EnhancedIconButton";

function EnhancedTableIconButton(props) {
  const { ariaLabel, onClick, Icon, tooltip, authority, disabled } = props;
  const calcDisabled = disabled || (authority && !GrantedAuthHelper.hasAuthority(authority));

  return (
    <EnhancedIconButton
      authority={authority}
      ariaLabel={ariaLabel}
      tooltip={tooltip}
      size="small"
      color="iconButton"
      Icon={Icon}
      onClick={onClick}
    />
  );
}

export default EnhancedTableIconButton;
