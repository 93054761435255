import alertHelper from "../alertHelper";
import ReservationDateValidator from "../ReservationDateValidator";

export function reservationValidator(formState, startAtWork, endAtWork) {
  if (startAtWork === undefined || endAtWork === undefined) {
    alertHelper.error({ message: "Datum rezervace se nepodařilo nastavit. Zkontrolujte zda je validní." });
    return false;
  }

  if (formState.title.length > 99) {
    alertHelper.error({ message: "Titulek nesmí být delší než 100 znaků!" });
    return false;
  }

  if (!ReservationDateValidator.isDateStartValid(startAtWork) && !ReservationDateValidator.isDateEndValid(endAtWork)) {
    alertHelper.error({ message: "Rezervace nelze uložit protože čas je mimo otevírací dobu!" });
    return false;
  }

  if (!formState.title) {
    alertHelper.error({ message: "Musíte zadat název (Tip: stačí pomlčka)", duration: 3000 });
    return false;
  }

  if (!formState.receptionistId) {
    alertHelper.error({ message: "Musíte zadat recepční", duration: 3000 });
    return false;
  }

  if (!formState.masseurId) {
    alertHelper.error({ message: "Musíte zadat maséra", duration: 3000 });
    return false;
  }

  return true;
}
