import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {getFetchURL} from "../helpers/actionHelpers";
import {fetchEventsAsync} from "./reservationSlice";
import {checkFreePassLogHistory} from "./passLogSlice";

const initialState = {
  appliedTicket: {}
};

export const applyTicketAsync = createAsyncThunk("apply/applyVoucher", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/reservation/apply")
  };

  const response = await axios(options).then((r) => {
    if (r.data?.passLog) {
      dispatch(checkFreePassLogHistory(r.data.passLog.id));
    }

    return r;
  });

  return response.data;
});

export const applySlice = createSlice({
  name: "apply",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(applyTicketAsync.fulfilled, (state, action) => {
        state.voucher = action.payload;
      });
  }
});

export default applySlice.reducer;
