import Grid from "@mui/material/Grid";
import { makeStyles } from '@mui/styles';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Check} from "@mui/icons-material";
import {
  checkFilteredPayments,
  fetchFilteredPayments,
  fetchCalendarMassagePrices,
  fetchPaymentTypes
} from "../../../features/paymentSlice";
import alertHelper from "../../../helpers/alertHelper";
import EnhancedTextField from "../../enhanced/EnhancedTextField";
import EnhancedSelect from "../../enhanced/EnhancedSelect";
import PaymentTypeResolver from "../../../helpers/paymentTypeResolver";
import EnhancedIconButton from "../../enhanced/EnhancedIconButton";

function HMPaymentSection(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const dataById = useSelector((state) => state.reservation.dataById);
  const paymentObj = useSelector((state) => state.payment.paymentObj);
  const validation = useSelector((state) => state.payment.validation);
  const voucher = useSelector((state) => state.applyTicket.voucher);
  const paymentTypeList = useSelector((state) => state.payment.paymentTypeList);
  const filteredPaymentsList = useSelector((state) => state.payment.filteredPaymentsList);
  const typePriceOfMassageList = useSelector((state) => state.payment.typePriceOfMassageList);
  const passLogHistoryFreeCount = useSelector((state) => state.passLog.passLogHistoryFreeCount);

  const { selected, setFormState, open, formState } = props;

  // pay a reservations without passlog/voucher
  useEffect(() => {
    paymentObj &&
      setFormState((formState) => ({
        ...formState,
        paymentId: paymentObj?.id,
        paymentTypeId: paymentObj?.paymentType?.id,
        paymentTypeLabel: paymentObj?.paymentType?.label,
        typeOfMassageLabel: paymentObj?.massageType?.name,
        voucherNumber: paymentObj?.voucherNumber,
        typeOfMassageId: paymentObj?.massageType?.id,
        massageTypeShort: paymentObj?.massageType?.short,
        billUUID: paymentObj?.billUUID,
        invoiceAddress: paymentObj?.invoiceAddress,
        voucherApplied: paymentObj?.applied,
        voucherNumberVisible: paymentObj?.voucherNumberVisible,
        passLogHistoryFreeCount: null
      }));
  }, [paymentObj, setFormState]);

  useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      voucherApplied: (voucher?.reservation?.id && voucher?.passLog?.id) || voucher?.dateUsed
    }));
  }, [setFormState, voucher]);

  useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      passLogHistoryFreeCount: passLogHistoryFreeCount.free
    }));
  }, [setFormState, passLogHistoryFreeCount]);

  useEffect(() => {
    if (formState.passLogHistoryFreeCount) {
      alertHelper.success({
        message: 'Zbývá ' + formState.passLogHistoryFreeCount + " masáží!",
        duration: 1000
      });
    }

  }, [formState]);

  useEffect(() => {
    if (open && !formState.paymentId) {
      dispatch(fetchPaymentTypes());
      formState.paymentTypeId && dispatch(fetchCalendarMassagePrices({
        paymentTypeId: formState.paymentTypeId,
        length: getReservationLength()
      }));
    }
  }, [open, formState.typeOfMassageId, formState.paymentTypeId, formState.paymentId, dispatch]);

  useEffect(() => {
    if (filteredPaymentsList?.length === 1) {
      const payment = filteredPaymentsList[0];
      if (payment?.applied) {
        alertHelper.error({
          message: "Tento dárkový poukaz / permanentka nelze použít."
        });
      } else if (payment?.afterExpire) {
        alertHelper.error({
          message: "Tento dárkový poukaz / permanentka již není platná."
        });
      } else {
        setFormState((formState) => ({
          ...formState,
          paymentId: payment?.id,
          paymentTypeId: payment?.paymentType?.id,
          paymentTypeLabel: payment?.paymentType?.label,
          typeOfMassageLabel: payment?.massageType?.name,
          billUUID: payment?.billUUID,
          typeOfMassageId: payment?.massageType?.id,
          massageTypeShort: payment?.massageType?.short,
          voucherNumber: payment?.voucherNumber,
          title: payment?.massageType?.short + " - " + formState.title,
          invoiceAddress: payment?.invoiceAddress,
          voucherApplied: payment?.applied,
          voucherNumberVisible: payment?.voucherNumberVisible,
          passLogHistoryFreeCount: null
        }));
      }
    }
  }, [filteredPaymentsList, setFormState]);

  useEffect(() => {
    let payment = dataById?.[selected]?.payment;

    setFormState((formState) => ({
      ...formState,
      paymentId: payment?.id,
      paymentTypeId: payment?.paymentType?.id,
      paymentTypeLabel: payment?.paymentType?.label,
      typeOfMassageLabel: payment?.massageType?.name,
      voucherNumber: payment?.voucherNumber,
      typeOfMassageId: payment?.massageType?.id,
      massageTypeShort: payment?.massageType?.short,
      billUUID: payment?.billUUID,
      invoiceAddress: payment?.invoiceAddress,
      voucherApplied: payment?.applied,
      voucherNumberVisible: payment?.voucherNumberVisible,
      passLogHistoryFreeCount: null
    }));
  }, [dataById, setFormState, selected]);

  function handleChange(event) {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));

    if (event.target.name === "typeOfMassageId") {
      const massageTypeObj = typePriceOfMassageList.find((p) => p.id === +event.target.value);
      if (massageTypeObj) {
        // check for undefined massageTypeObj
        setFormState((formState) => ({
          ...formState,
          title: `${massageTypeObj.short} - ${formState.title}`
        }));
      }
    }

    if (event.target.name === "paymentTypeId") {
      if (isCashOrCreditCard(event.target.value)) {
        setFormState((formState) => ({
          ...formState,
          voucherNumber: null
        }));
      }

      setFormState((formState) => ({
        ...formState,
        typeOfMassageId: null
      }));
    }

    if (event.target.name === "voucherNumber" && event.target.value.length > 6) {
      dispatch(checkFilteredPayments(event.target.value));
    }

    if (event.target.name === "voucherNumber" && event.nativeEvent.data === ' ') {
      setFormState((formState) => ({
        ...formState,
        voucherNumber: event.target.value.replace(' ', '')
      }));
    }

    if (event.target.name === "voucherNumber" && isDisabledVoucherNumber()) {
      setFormState((formState) => ({
        ...formState,
        voucherNumber: null
      }));
    }
  }

  function isCashOrCreditCard(value)
  {
    return PaymentTypeResolver.isCash(value) || PaymentTypeResolver.isCreditCard(value)
  }

  function handleFind() {
    if (formState.voucherNumber) {
      dispatch(fetchFilteredPayments(formState.voucherNumber));
    }
  }

  let foundValidVoucherNumber = validation?.isVoucherNumberValid;

  function isVNFieldNonValid() {
    if (
        formState?.voucherNumber && (
            (formState.voucherNumber.length > 0 && !foundValidVoucherNumber) &&
            formState.voucherNumber.length  !== 14
        )
    ) {
      return true;
    }
    
    return false;
  }

  function isDisabledVoucherNumber()
  {
    return !!formState.paymentId || isCashOrCreditCard(formState.paymentTypeId);
  }

  function isDisabledCheckButton()
  {
    return !!formState.paymentId || isReservationLongerThenOneHour();
  }

  function isReservationLongerThenOneHour()
  {
    return getReservationLength() > 60;
  }

  function getReservationLength()
  {
    return (formState.endAtWork - formState.startAtWork) / 60000;
  }

  function checkTooltip()
  {
    return (isReservationLongerThenOneHour()
    ? "Poukaz nejde použít, protože rezervace je delší než 60 minut."
    : "Vyhledat poukaz"
    );
  }

  return (
    <>
      <Grid item xs={6}>
        <EnhancedTextField
          name="voucherNumber"
          value={isDisabledVoucherNumber() ? formState.voucherNumberVisible : formState.voucherNumber}
          label="Číslo poukazu"
          onChange={handleChange}
          className={`${foundValidVoucherNumber ? classes.voucherTextField : ""}`}
          error={isVNFieldNonValid()}
          disabled={isDisabledVoucherNumber()}
        />
      </Grid>
      <Grid item xs={2}>
        <EnhancedIconButton
            authority="calendar.edit"
            tooltip={checkTooltip()}
            size="medium"
            Icon={Check}
            onClick={handleFind}
            className={classes.check}
            disabled={isDisabledCheckButton()}
            sx={buttonStyle.check}

        />
      </Grid>
      <Grid item xs={4}>
        <EnhancedTextField
          name="billUUID"
          value={formState.billUUID}
          label="Číslo dokladu"
          onChange={handleChange}
          disabled={true}
        />
      </Grid>
      <Grid item xs={12}>
        {formState.paymentId ? (
          <EnhancedTextField
            name="paymentTypeLabel"
            value={formState.paymentTypeLabel}
            label="Způsob platby"
            onChange={handleChange}
            disabled={true}
          />
        ) : (
          <EnhancedSelect
            name="paymentTypeId"
            value={formState.paymentTypeId}
            label="Způsob platby"
            list={paymentTypeList}
            onChange={handleChange}
            disabled={!!formState.paymentId}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        {formState.paymentId ? (
          <EnhancedTextField
            name="typeOfMassageLabel"
            value={formState.typeOfMassageLabel}
            label="Typ a cena masáže"
            onChange={handleChange}
            disabled={true}
          />
        ) : (
          <EnhancedSelect
            name="typeOfMassageId"
            value={formState.typeOfMassageId}
            label="Typ a cena masáže"
            list={typePriceOfMassageList}
            onChange={handleChange}
            disabled={!!formState.paymentId}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <EnhancedTextField
            name="invoiceAddress"
            value={formState.invoiceAddress}
            label="Fakturační adresa"
            onChange={handleChange}
            disabled={!!formState.paymentId}
        />
      </Grid>
    </>
  );
}

const buttonStyle = {
  check: {
    color: "white",
    background: "#008ead",
    "&:hover": {
      color: "white",
      background: "#015e72"
    },
    marginLeft: "-0.5em",
    marginTop: "0.1em",
    height: "2em",
    width: "2em",
    borderRadius: "0.3em",
    "&:disabled": {
      background: "#E8E8E8",
      pointerEvents: "auto"
    }
  }
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%"
  },
  voucherTextField: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important" // override inline-style
    }
  }
}));

export default HMPaymentSection;
