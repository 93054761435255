import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from "react-redux";
import EnhancedTableToolbar from "../enhanced/EnhancedTableToolbar";
import {fetchHppInfo, editHppInfo} from "../../features/settingsSlice";
import UserRoleHelper from "../../helpers/UserRoleHelper";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import EnhancedConfirmButton from "../enhanced/EnhancedConfirmButton";

function HppInfo() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const hppInfo = useSelector((state) => state.settingsSlice.settingsConst);
  const [formState, setFormState] = useState({});

  useEffect(() => {
    dispatch(fetchHppInfo());
  }, [dispatch]);

  useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      value: hppInfo?.value,
    }));
  }, [hppInfo]);

  function saveDocument() {
    var apiData= {
      'value': formState.value
    }

    dispatch(editHppInfo(apiData));
  }

  return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar label="Informace o práv. vztahu založeném na HPP" />
          <Grid container item spacing={1} xs={9}>
            <Grid item xs={9} ml={"auto"} mp={"auto"} mb={4}>
              <Grid>
                {UserRoleHelper.isAdmin() ? (
                        <CKEditor
                            editor={ ClassicEditor }
                            data={formState.value || ''}
                            onReady={ editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={ ( event, editor ) => {
                              formState.value = editor.getData();
                            } }
                            onBlur={ ( event, editor ) => {
                              console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                              console.log( 'Focus.', editor );
                            } }
                        />
                    ) :
                    <div dangerouslySetInnerHTML={{__html: hppInfo.value}}/>
                }
              </Grid>
              <Grid item xs={9} sx={{float: "right"}} mt={3}>
                { UserRoleHelper.isAdmin() ? (
                    <EnhancedConfirmButton handleOk={saveDocument} okLabel="Upravit" />
                ) : null}
              </Grid>
            </Grid>

          </Grid>
        </Paper>
      </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(4),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  formControl: {
    width: "100%",
    padding: '5px'
  }
}));

export default HppInfo;
