import {Button, colors, List, ListItem, TableRow} from "@mui/material";
import {makeStyles, styled} from '@mui/styles';
import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import GrantedAuthHelper from "../../../../helpers/GrantedAuthHelper";

const SidebarNav = (props) => {
  const { pages } = props;
  const classes = useStyles();
  const filteredPages = pages.filter((p) => GrantedAuthHelper.hasAuthority(p.authority));

  return (
    <List className={classes.root}>
      {filteredPages.map((page, i) => (
        <CustomList className={classes.item} key={page.title + i}>
          <CustomMenuButton
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            onClick={() => props.onClose()}
            to={page.href}
          >
            <div className={classes.icon}>
              {page.icon}
            </div>
            {page.title}
          </CustomMenuButton>
        </CustomList>
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.array.isRequired
};

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const CustomMenuButton = styled(Button)(({ theme }) => ({
  // for some reason empty it works too
}));

const CustomList = styled(List)(({ theme }) => ({
  // for some reason empty it works too
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "20em",
    marginBottom: theme.spacing(2)
  },
  button: {
    display:"block",
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium
  },
  item: {
    display: "inline",
    paddingTop: 0,
    paddingBottom: 0,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: "inline",
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main
    }
  }
}));

export default SidebarNav;
