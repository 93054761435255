import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {getFetchURL} from "../helpers/actionHelpers";
import {fetchPriceListByMassageType} from "./pricingSlice";

const initialState = {
  settingsConst: {}
};

export const fetchHppInfo = createAsyncThunk("settings/fetchHppInfo", async () => {
  const response = await axios.get(getFetchURL('/settings/get/hpp'));
  return response.data;
});

export const editHppInfo = createAsyncThunk("settings/editHppInfo", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL('/settings/edit/hpp')
  };

  const response = await axios(options);
  return response.data;
});

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHppInfo.fulfilled, (state, action) => {
        state.settingsConst = action.payload;
      })
  }
});

export default settingsSlice.reducer;
