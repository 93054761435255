import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getFetchURL } from "../helpers/actionHelpers";
import GrantedAuthHelper from "../helpers/GrantedAuthHelper";
import { cleanSession, setSessionInitAttr } from "../helpers/sessionHelper";

const initialState = {
  main: {},
  role: {}
};

export const signInRequestAsync = createAsyncThunk("auth/signInRequest", async ({ values, history }, { dispatch }) => {
  const apiData = {};
  apiData.username = values.email;
  apiData.password = values.password;

  const options = {
    method: "POST",
    data: apiData,
    url: getFetchURL("/user/login-check")
  };

  const response = await axios(options).then((_) => {
    history.push("/");
  });

  return response.data;
});

export const fetchUserProfileAsync = createAsyncThunk("auth/fetchUserProfile", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/user/profile`));

  return response.data;
});

export const fetchUserRoleAsync = createAsyncThunk("auth/fetchUserRoleAsync", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/acl/user/get`));

  return response.data;
});

export const logoutAsync = createAsyncThunk("auth/logoutAsync", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/user/logout`));
  return response.data;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    removeUserProfile: (state) => {
      state.main = {};
    },
    removeUserRole: (state) => {
      state.role = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInRequestAsync.fulfilled, (state) => {
        return state;
      })
      .addCase(fetchUserProfileAsync.fulfilled, (state, action) => {
        setSessionInitAttr();
        state.main = action.payload;
      })
      .addCase(fetchUserRoleAsync.fulfilled, (state, action) => {
        setSessionInitAttr();
        state.role = action.payload;
      });
  }
});

export const { removeUserProfile, removeUserRole } = authSlice.actions;

export const signOutRequest = (history) => (dispatch) => {
  cleanSession();
  dispatch(removeUserProfile());
  dispatch(removeUserRole());
  GrantedAuthHelper.empty();
  history.push("/sign-in");
};

export default authSlice.reducer;
