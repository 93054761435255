import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getFetchURL } from "../helpers/actionHelpers";
import {getSessionCompanyId} from "../helpers/sessionHelper";

const initialState = {
  massageTypeList: [],
  massagePriceList: []
};

export const fetchMassageTypeList = createAsyncThunk("pricing/fetchMassageTypeList", async (_,{ dispatch }) => {
  const response = await axios.get(getFetchURL(`/massage-type/get`));
  return response.data;
});

export const fetchPriceListByMassageType = createAsyncThunk("pricing/fetchPriceListByMassageType", async (massageTypeId, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/massage-price/get/${getSessionCompanyId()}/${massageTypeId}`));
  return response.data;
});

export const addMassagePrice = createAsyncThunk("pricing/addMassagePrice", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/massage-price/add")
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchPriceListByMassageType(apiData.massageTypeId));
  });

  return response.data;
});

export const pricingSlice = createSlice({
  name: "pricing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMassageTypeList.fulfilled, (state, action) => {
        state.massageTypeList = action.payload;
      })
      .addCase(fetchPriceListByMassageType.fulfilled, (state, action) => {
        state.massagePriceList = action.payload;
      })
      .addCase(addMassagePrice.fulfilled, (state, action) => {
        state.massagePriceList = action.payload;
      })
  }
});

export default pricingSlice.reducer;
