import axios from "axios";
import { useHistory } from "react-router-dom";
import { handleNetworkError } from "./actionHelpers";
import linearProgressHelper from "../helpers/linearProgressHelper";

export default function useErrorHandler() {
  const history = useHistory();
  handleErrors();

  function handleErrors() {
    axios.interceptors.request.use(
      function (config) {
        linearProgressHelper.show();
        return config;
      },
      function (error) {
        handleNetworkError({ errorReason: error }, { history });
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        linearProgressHelper.hide();
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        handleNetworkError({ errorReason: error }, { history });
        linearProgressHelper.hide();
        return Promise.reject(error);
      }
    );
  }

  return true;
}
