import { Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import { makeStyles } from '@mui/styles';
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import EnhancedConfirmButton from "../enhanced/EnhancedConfirmButton";
import EnhancedTextField from "../enhanced/EnhancedTextField";
import EnhancedDialogTitle from "../enhanced/EnhancedDialogTitle";
import {editCompanySum} from "../../features/companySumSlice";
import EnhancedSelect from "../enhanced/EnhancedSelect";
import UserRoleHelper from "../../helpers/UserRoleHelper";

function NewCompanySumDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {open, setOpenEdit, companySum} = props;
  const [formState, setFormState] = useState({});

  useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      companyName: companySum?.company?.name,
      validityTextDate: companySum?.validityTextDate,
      sumUpSum: companySum?.sumUpSum,
      slevomatSum: companySum?.slevomatSum,
      fakePrice: companySum?.fakePrice
    }));
  }, [companySum]);

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const handleClose = () => {
    setOpenEdit(false);
    setFormState({});
  };

  const handleOk = () => {
    const apiData = {
      id: companySum.id,
      sumUpSum: formState.sumUpSum,
      slevomatSum: formState.slevomatSum,
      fakePrice: formState.fakePrice
    };

    dispatch(editCompanySum(apiData));
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="form-dialog-title">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Úprava sumarizační hodnoty za organizaci" />
        <DialogContent>
          <Grid container spacing={3} sx={{marginTop: -2}}>
            <Grid item xs={6}>
              <EnhancedTextField
                  name="companyName"
                  value={formState.companyName}
                  disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField
                  name="validityTextDate"
                  value={formState.validityTextDate}
                  label="Platnost od - do"
                  disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField
                  name="sumUpSum"
                  value={formState.sumUpSum}
                  label="SumUp hodnota"
                  onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField
                  name="slevomatSum"
                  value={formState.slevomatSum}
                  label="Počet uplatněných poukazů"
                  onChange={handleChange}
              />
            </Grid>

            {UserRoleHelper.isSpravce() || UserRoleHelper.isAdmin() ? (
                <Grid item xs={12}>
                  <EnhancedTextField
                      name="fakePrice"
                      value={formState.fakePrice}
                      label="Korekce"
                      onChange={handleChange}
                  />
                </Grid>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <EnhancedConfirmButton handleOk={handleOk} okLabel="Uložit" />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}));

export default NewCompanySumDialog;
