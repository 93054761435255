class LinearProgressHelper {
  constructor() {
    this.linearProgress = null;
  }

  registerLinearProgress(linearProgress) {
    this.linearProgress = linearProgress;
  }

  unregisterLinearProgress() {
    this.linearProgress = null;
  }

  show() {
    if (this.linearProgress !== null) {
      this.linearProgress.setVisibility(true);
    }
  }

  hide() {
    if (this.linearProgress !== null) {
      this.linearProgress.setVisibility(false);
    }
  }
}

export default new LinearProgressHelper();
