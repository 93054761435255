import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { makeStyles } from '@mui/styles';
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import AutoCompInputCustomer from "./AutoCompInputCustomer";

function HMCustomerSection(props) {
  const classes = useStyles();
  const dataById = useSelector((state) => state.reservation.dataById);
  const customer = useSelector((state) => state.modalEdit.customer);

  const { selected, setFormState, formState } = props;

  useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      customer: customer,
      title: (formState.title ? `${formState.title} ${customer?.fullName}` : '')
    }));
  }, [customer, setFormState]);

  useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      customer: dataById?.[selected]?.customer
    }));
  }, [dataById, selected, setFormState]);

  return (
    <Grid item xs={12}>
      <FormControl variant="outlined" className={classes.formControl}>
        <AutoCompInputCustomer value={formState.customer} setFormState={setFormState} />
      </FormControl>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%"
  }
}));

export default HMCustomerSection;
