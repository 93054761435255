import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { editVoucher } from "../../features/voucherSlice";
import { exportBillPdf } from "../../features/paymentSlice";
import EnhancedConfirmButton from "../enhanced/EnhancedConfirmButton";
import EnhancedTextField from "../enhanced/EnhancedTextField";
import EnhancedDialogTitle from "../enhanced/EnhancedDialogTitle";
import EnhancedDateField from "../enhanced/EnhancedDateField";
import GrantedAuthHelper from "../../helpers/GrantedAuthHelper";
import Moment from "moment";
import {getDBDateFromMomentObject} from "../../helpers/DateConvertor";

function VoucherEditDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { open, setOpen, voucher } = props;
  const [formState, setFormState] = useState({});
  useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      title: voucher?.title,
      number: voucher?.number,
      numberVisible: voucher?.numberVisible,
      dateExpire: new Moment(voucher?.dateExpire),
      billUUID: voucher?.billUUID,
      showExportBillButton: voucher?.showExportBillButton
    }));
  }, [voucher]);

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const handleChangeDateExpire = (event) => {
    setFormState((formState) => ({
      ...formState,
      ['dateExpire']: event
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    const apiData = {};
    apiData.id = voucher.id;
    apiData.title = formState.title;
    apiData.voucherNumber = formState.number;
    apiData.dateExpire = getDBDateFromMomentObject(formState.dateExpire);
    dispatch(editVoucher(apiData));
    handleClose();
  };

  function handleExportBill() {
    if (formState.billUUID) {
      dispatch(exportBillPdf(formState.billUUID));
    }
  }

  function isExportBillDisabled() {
    return !formState.showExportBillButton;
  }

  function isExpiredDisabled() {
    return !GrantedAuthHelper.hasAuthority("voucher.all");
  }

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="form-dialog-title">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Úprava dárkového poukazu" />
        <DialogContent>
          <Grid container spacing={3} sx={{marginTop: -2}}>
            <Grid item xs={6}>
              <EnhancedTextField name="title" value={formState.title} label="Jméno" onChange={handleChange} />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField
                name="numberVisible"
                value={formState.numberVisible}
                label="Číslo poukazu"
                onChange={handleChange}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedDateField
                  name="dateExpire"
                  value={formState.dateExpire}
                  label="Platnost do"
                  handleChange={handleChangeDateExpire}
                  disabled={isExpiredDisabled()}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <EnhancedConfirmButton handleClose={handleClose} handleOk={handleOk} okLabel="Uložit" />
          <Button
              onClick={handleExportBill}
              color="primary"
              variant="contained"
              className={classes.exportBill}
              disabled={isExportBillDisabled()}
          >
            Účtenka
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  exportBill: {
    color: "white",
    background: "#33cccc",
    "&:hover": {
      color: "white",
      background: "#29a3a3"
    }
  }
}));

export default VoucherEditDialog;
