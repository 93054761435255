import React, { useState} from "react";
import {Grid, Paper} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useSelector} from "react-redux";
import PaymentSearchForm from "./PaymentSearchForm";
import PaymentTable from "./PaymentTable";
import PaymentEditDialog from "./PaymentEditDialog";

function PaymentPage() {
  const classes = useStyles();
  const filteredPaymentsList = useSelector((state) => state.payment.filteredPaymentsList);

  const paymentViewConstants = { TABLE_VIEW: 0, FORM_VIEW: 1 };
  const [paymentView, setPaymentView] = useState(paymentViewConstants.FORM_VIEW);

  const [open, setOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState({});

  const handleBackBtn = () => {
      setPaymentView(paymentViewConstants.FORM_VIEW);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid>
            <PaymentEditDialog
                open={open}
                payment={selectedPayment}
                setOpen={setOpen}
            />
            <PaymentSearchForm
                className={paymentView === paymentViewConstants.FORM_VIEW ? "" : classes.dNone}
                setPaymentView={setPaymentView}
                paymentViewConstants={paymentViewConstants}
            />
            <PaymentTable
                className={paymentView === paymentViewConstants.TABLE_VIEW ? "" : classes.dNone}
                filteredPaymentsList={filteredPaymentsList}
                handleBackBtn={handleBackBtn}
                open={open}
                payment={selectedPayment}
                setOpen={setOpen}
                setSelectedPayment={setSelectedPayment}
            />
        </Grid>
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        fontFamily: "Roboto, Helvetica, Arial, sans-serif !important"
    },
    dNone: {
        display: "none"
    },
    serachInput: { marginBottom: theme.spacing(2) }
}));

export default PaymentPage;
