import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EnhancedDialogTitle from "../enhanced/EnhancedDialogTitle";
import {fetchPriceListByMassageType} from "../../features/pricingSlice";

function PriceHistoryDialog(props) {
  const classes = useStyles();
  const { open, setOpenHistory, massageType } = props;
  const dispatch = useDispatch();
  const priceHistoryList = useSelector((state) => state.pricing.massagePriceList);

  const handleClose = () => {
    setOpenHistory(false);
  };

  useEffect(() => {
    if (typeof massageType.id !== "undefined") {
      dispatch(fetchPriceListByMassageType(massageType.id));
    }
  }, [massageType, dispatch]);

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="form-dialog-title">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Historie permanentky" />
        <DialogContent>
          <TableContainer>
            {priceHistoryList.length === 0 ? null : (
              <Table aria-label="passlog table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">Cena</TableCell>
                    <TableCell align="right">Plat</TableCell>
                    <TableCell align="right">Stav</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {priceHistoryList.map((p, i) => (
                    <TableRow key={i}>
                      <TableCell align="right">{p.price}</TableCell>
                      <TableCell align="right">{p.salary}</TableCell>
                      <TableCell align="right">{p.active === true ? 'Aktivní' : 'Neaktivní' }</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}));

export default PriceHistoryDialog;
