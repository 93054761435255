import { Dialog, DialogActions, DialogContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EnhancedConfirmButton from "../enhanced/EnhancedConfirmButton";
import EnhancedDialogTitle from "../enhanced/EnhancedDialogTitle";
import EnhancedSelect from "../enhanced/EnhancedSelect";
import EnhancedTextField from "../enhanced/EnhancedTextField";
import { addDailyNote, deleteDailyNote, editDailyNote } from "../../features/dailyNoteSlice";
import { fetchRooms } from "../../features/listsSlice";
import { getSessionCheckedCompany, getSessionCheckedRooms } from "../../helpers/sessionHelper";
import alertHelper from "../../helpers/alertHelper";
import EnhancedDateField from "../enhanced/EnhancedDateField";
import Moment from "moment";
import EnhancedButton from "../enhanced/EnhancedButton";
import {getDBDateFromMomentObject} from "../../helpers/DateConvertor";

function DailyNoteDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const dataById = useSelector((state) => state.dailyNote.dataById);
  const roomList = useSelector((state) => state.lists.roomList);

  const { selected, setSelected, openDailyNote, setOpenDailyNote } = props;

  const [formState, setFormState] = useState({});

  useEffect(() => {
    if (getSessionCheckedCompany() && openDailyNote) dispatch(fetchRooms(getSessionCheckedCompany()));
  }, [openDailyNote, dispatch, selected]);

  useEffect(() => {
    if (openDailyNote && dataById[selected]) {
      setFormState((formState) => ({
        ...formState,
        id: dataById[selected]?.id,
        note: dataById[selected]?.title,
        date: new Moment(dataById[selected]?.date),
        room: dataById[selected]?.room?.id
      }));
    }
  }, [openDailyNote, dataById, selected, setFormState]);

  useEffect(() => {
    if (openDailyNote && selected === null && getSessionCheckedRooms().length === 1) {
      setFormState((formState) => ({
        ...formState,
        room: getSessionCheckedRooms().map((c) => c[0])[0]
      }));
    }
  }, [setFormState, openDailyNote, selected]);

  function handleChange(event) {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  }

  const handleChangeDate = (event) => {
    setFormState((formState) => ({
      ...formState,
      ['date']: event
    }));
  };

  function handleClose() {
    setSelected(null);
    setFormState({});
    setOpenDailyNote(false);
  }

  function handleOk() {
    const apiData = {
      note: formState.note,
      date: getDBDateFromMomentObject(formState.date),
      roomId: formState.room
    };

    handleClose();

    if (dataById[selected]) {
      apiData.id = formState.id;
      dispatch(editDailyNote(apiData));
    } else {
      dispatch(addDailyNote(apiData));
    }
  }

  const handleDelete = () => {
    alertHelper.confirm({
      message: "Opravdu si přejete smazat tuto denní poznámku?",
      callback: () => dispatch(deleteDailyNote(formState.id))
    });

    handleClose();
  };

  function isDisabledFields() {
    return formState.id;
  }

  return (
    <div className={classes.root}>
      <Dialog
        open={openDailyNote}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Denní poznámka" />
        <DialogContent>
          <Grid container spacing={3} sx={{marginTop: -2}}>
            <Grid item xs={12}>
              <EnhancedTextField name="note" value={formState.note || ""} label="Poznámka" onChange={handleChange} />
            </Grid>
            <Grid item xs={6}>
              <EnhancedDateField
                  disabled={isDisabledFields()}
                  name="date"
                  value={formState.date || null}
                  label="Datum"
                  handleChange={handleChangeDate}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedSelect
                disabled={isDisabledFields()}
                name="room"
                value={formState.room || ""}
                label="Masérna"
                list={roomList}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <EnhancedButton
              onClick={handleDelete}
              disabled={!formState.id}
              color="secondary"
              variant="contained"
              sx={style.delete}
              label="Smazat"
          />
          <EnhancedConfirmButton handleOk={handleOk} disabledOk={false} />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const style = {
  delete: {
    color: "white",
    background: "red",
    "&:hover": {
      color: "white",
      background: "#B22222"
    }
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}));

export default DailyNoteDialog;
