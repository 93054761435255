import React from "react";
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import Table from "@mui/material/Table";
import Paper from '@mui/material/Paper';

function EnhancedTable(props) {
  const { headerList, dataList, renderRowCells } = props;

  if (dataList?.length === 0) return null;

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="enhanced table">
          <TableHead>
            <Headers list={headerList} />
          </TableHead>
          <TableBody>
            <Rows list={dataList} renderRowCells={renderRowCells} />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function Headers(props) {
  return (
    <StyledTableRow>
      {props.list.map((h) => (
        <TableCell key={h.label} align="right">
          {h.label}
        </TableCell>
      ))}
    </StyledTableRow>
  );
}

function Rows(props) {
  const { list, renderRowCells } = props;
  const classes = useRowStyles();

  return (
    <>
      {list.map((l, i) => (
        <TableRow key={l.id} style={{ backgroundColor: i % 2 ? "#e1e1e1" : "white" }} className={classes.root}>
          {renderRowCells(l)}
        </TableRow>
      ))}
    </>
  );
}

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset"
    }
  }
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
    padding: "1.5em"
  },
}));

export default EnhancedTable;
