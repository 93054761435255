import React, {useState} from "react";
import {Grid, TableCell} from "@mui/material";
import Paper from "@mui/material/Paper";
import { makeStyles } from '@mui/styles';
import EnhancedTableToolbar from "../enhanced/EnhancedTableToolbar";
import EnhancedTable from "../enhanced/EnhancedTable";
import EnhancedIconButton from "../enhanced/EnhancedIconButton";
import {ArrowBack, Edit} from "@mui/icons-material";
import AttendanceListEditDialog from "./AttendanceListEditDialog";

const AttendanceListFoundData = (props) => {
    const classes = useStyles();
    const { attendanceUserList, setEventView, eventViewConstants } = props;
    const [open, setOpen] = useState(false);
    const [selectedAttendanceList, setSelectedAttendanceList] = useState({});

    const handleBackBtn = () => {
        setEventView(eventViewConstants.ATTENDANCE_FORM_VIEW);
    };

    const handleEdit = (attendanceList) => {
        setSelectedAttendanceList(attendanceList);
        setOpen(true);
    };

  const headerList = [
    { align: "left", label: "" },
    { align: "left", label: "Příchod" },
    { align: "left", label: "Odchod" },
  ];

  const renderRowCells = (attendance) => (
      <>
        <TableCell className={classes.iconCell}>
          <EnhancedIconButton
              authority="attenance.edit"
              ariaLabel="edit row"
              tooltip="Editace docházky"
              size="small"
              Icon={Edit}
              onClick={() => handleEdit(attendance)}
          />

        </TableCell>
        <TableCell align="left">{attendance["arrival"]}</TableCell>
        <TableCell align="left">{attendance["departure"]}</TableCell>
      </>
  );

  return (
      <Paper className={classes.paper}>
          <Grid item>
              <EnhancedIconButton ariaLabel="back icon" tooltip="Zpět" Icon={ArrowBack} onClick={handleBackBtn} />
          </Grid>
          <EnhancedTableToolbar label="Úprava docházky zaměstnance" />
          <EnhancedTable headerList={headerList} dataList={attendanceUserList} renderRowCells={renderRowCells} />
          <AttendanceListEditDialog open={open} attendanceList={selectedAttendanceList} setOpen={setOpen} />
        </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: '5px'
  },
  textField: {
    width: 260
  }
}));

export default AttendanceListFoundData;
