import React from "react";
import {Checkbox, FormControlLabel, Toolbar, Typography} from "@mui/material";
import { makeStyles } from '@mui/styles';
import {Add} from "@mui/icons-material";
import EnhancedIconButton from "../enhanced/EnhancedIconButton";
import UserRoleHelper from "../../helpers/UserRoleHelper";

function EnhancedPasslogTableToolbar(props) {
  const classes = useToolbarStyles();

  const { setOpenNew, label, onChange } = props;

  return (
    <Toolbar className={classes.root}>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        {label}
      </Typography>
      <div>
        {UserRoleHelper.isAdmin() || UserRoleHelper.isSpravce() ?
          <FormControlLabel
            value="Neplatné poukazy"
            name="expiredPasslogs"
            control={<Checkbox />}
            label="Neplatné poukazy"
            onChange={onChange}
          /> : null
        }
      </div>
      <div>
        <EnhancedIconButton
          authority='passlog.edit'
          ariaLabel="add item"
          tooltip="Přidat"
          Icon={Add}
          color="primary"
          onClick={() => setOpenNew(true)}
        />
      </div>
    </Toolbar>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  title: {
    flex: "1 1 100%"
  },
  dNone: {
    display: "none"
  }
}));

export default EnhancedPasslogTableToolbar;
