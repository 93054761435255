import React from "react";
import {Grid, TableCell} from "@mui/material";
import EnhancedIconButton from "../enhanced/EnhancedIconButton";
import {ArrowBack, Edit} from "@mui/icons-material";
import EnhancedTable from "../enhanced/EnhancedTable";
import EnhancedTableIconButton from "../enhanced/EnhancedTableIconButton";

const PaymentTable = (props) => {
  const {
    filteredPaymentsList,
    handleBackBtn,
    className,
    setOpen,
    setSelectedPayment
  } = props;

  const handleEdit = (payment) => {
    setSelectedPayment(payment);
    setOpen(true);
  };

  const headerList = [
    { align: "left", label: "" },
    { align: "left", label: "Číslo účtenky" },
    { align: "left", label: "Metoda" },
    { align: "left", label: "Typ masáže" },
    { align: "left", label: "Číslo poukazu" },
    { align: "left", label: "Cena" },
    { align: "left", label: "Vytvořil/a" },
    { align: "left", label: "Upravil/a" }
  ];


  const renderRowCells = (payment) => (

    <>
      <TableCell>
        <EnhancedTableIconButton
          authority="payment.edit"
          ariaLabel="edit row"
          tooltip="Upravit"
          size="small"
          Icon={Edit}
          onClick={() => handleEdit(payment)}
        />
      </TableCell>
      <TableCell align="right">{payment?.billUUID}</TableCell>
      <TableCell align="right">{payment?.paymentType?.name}</TableCell>
      <TableCell align="right">{payment?.massageType?.name}</TableCell>
      <TableCell align="right">{payment?.voucherNumber}</TableCell>
      <TableCell align="right">{payment?.massagePrice?.price},-</TableCell>
      <TableCell align="right">{payment?.createdBy?.name} ({ payment?.createdText })</TableCell>
      <TableCell align="right">{payment?.editedBy?.name} { payment?.editedText !== null ? '('+payment?.editedText+')' : null }</TableCell>
    </>
  );

  return (
    <>
      <Grid item className={className}>
        <EnhancedIconButton ariaLabel="back icon" tooltip="Zpět" Icon={ArrowBack} onClick={handleBackBtn} />
        <EnhancedTable headerList={headerList} dataList={filteredPaymentsList} renderRowCells={renderRowCells} />
      </Grid>
    </>
  );
};

export default PaymentTable;
