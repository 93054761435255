import { AppBar, Badge, Hidden, Toolbar, Typography } from "@mui/material";
import {
  Input,
  Menu as MenuIcon,
  NotificationsOutlined as NotificationsIcon,
  Settings as SettingsIcon
} from "@mui/icons-material";
import { makeStyles } from '@mui/styles';
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {signOutRequest, logoutAsync} from "../../../features/authSlice";
import {getSessionCompanyName, getSessionRooms} from "../../../helpers/sessionHelper";
import EnhancedIconButton from "../../enhanced/EnhancedIconButton";
import Settings from "../../Settings";

function Topbar(props) {
  const { onSidebarOpen } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const onSignOut = () => {
    dispatch(signOutRequest(history));
    dispatch(logoutAsync());
  };

  const [openSettings, setOpenSettings] = useState(false);
  useEffect(() => {
    if (Object.keys(getSessionRooms()).length === 0) setOpenSettings(true);
  }, []);

  const headerLabel = getSessionCompanyName();

  return (
    <AppBar className={classes.root}>
      <Toolbar>
        <RouterLink to="/" className={classes.title}>
          <Typography variant="h3" component="h1" className={classes.title} sx={titleStyle.title}>
            {headerLabel}
          </Typography>
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <EnhancedIconButton
            ariaLabel="notifications"
            tooltip="Oznámení"
            color="inherit"
            Icon={() => (
              <Badge badgeContent={0} color="primary" variant="dot">
                <NotificationsIcon />
              </Badge>
            )}
            onClick={() => {}}
          />
        </Hidden>
        <Hidden lgUp>
          <EnhancedIconButton ariaLabel="menu" tooltip="Menu" color="inherit" Icon={MenuIcon} onClick={onSidebarOpen} />
        </Hidden>
        <EnhancedIconButton
          ariaLabel="settings"
          tooltip="Nastavení"
          color="inherit"
          Icon={SettingsIcon}
          onClick={() => setOpenSettings(true)}
        />
        <EnhancedIconButton ariaLabel="logout" tooltip="Odhlásit" color="inherit" Icon={Input} onClick={onSignOut} />
      </Toolbar>
      <Settings openSettings={openSettings} setOpenSettings={setOpenSettings} />
    </AppBar>
  );
}

const titleStyle = {
  title: {
    color: "white",
    textDecoration: "none"
  }
}

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none"
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));


export default Topbar;
