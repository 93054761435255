import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import GrantedAuthHelper from "../../helpers/GrantedAuthHelper";

function EnhancedIconButton(props) {
  const { ariaLabel, size, onClick, Icon, tooltip, className, color, authority, disabled, sx } = props;
  const calcDisabled = disabled || (authority && !GrantedAuthHelper.hasAuthority(authority));

  return (
    <Tooltip title={tooltip}>
      <IconButton
        className={className}
        disabled={calcDisabled}
        aria-label={ariaLabel}
        size={size}
        color={color}
        onClick={onClick}
        sx={sx}
      >
        <Icon />
      </IconButton>
    </Tooltip>
  );
}

export default EnhancedIconButton;
