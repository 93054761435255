import "@fortawesome/fontawesome-free/css/all.css";
import { FormControl, Grid, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import {  Search } from "@mui/icons-material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchFilteredCustomers } from "../../features/modalEditSlice";
import EnhancedIconButton from "../enhanced/EnhancedIconButton";

const CustomerSearchForm = (props) => {
  const dispatch = useDispatch();

  const { setCustomerView, customerViewConstants, className, searchValue, setSearchValue } = props;

  const handleSearch = () => {
    dispatch(fetchFilteredCustomers(searchValue));
    setCustomerView(customerViewConstants.TABLE_VIEW);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") handleSearch();
  };

  return (
      <Grid item xs={6} sx={{marginTop: 1}}>
        <FormControl onKeyDown={(e) => handleKeyDown(e)} fullWidth variant="outlined">
          <InputLabel>Vyhledat</InputLabel>
          <OutlinedInput
              id="outlined-adornment-amount"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <EnhancedIconButton ariaLabel="back icon" tooltip="Zpět" Icon={Search} onClick={handleSearch} />
                </InputAdornment>
              }
          />
        </FormControl>
      </Grid>
  );
};

export default CustomerSearchForm;
