import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { makeStyles, useTheme } from '@mui/styles';
import clsx from "clsx";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Footer from "src/components/layouts/main/Footer";
import Sidebar from "src/components/layouts/main/sidebar/Sidebar";
import Topbar from "src/components/layouts/main/Topbar";
import LinearProgress from "src/components/LinearProgress";
import { fetchUserProfileAsync, fetchUserRoleAsync } from "src/features/authSlice";
import { getSessionInitAttr } from "src/helpers/sessionHelper";
import useErrorHandler from "src/helpers/useErrorHandler";

function Main(props) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  useErrorHandler();

  const { children } = props;
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);
  const handleSidebarOpen = () => setOpenSidebar(true);
  const handleSidebarClose = () => setOpenSidebar(false);

  useEffect(() => {
    dispatch(fetchUserProfileAsync());
    dispatch(fetchUserRoleAsync());
  }, [dispatch]);

  if (!getSessionInitAttr()) {
    return <LinearProgress />;
  }

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}>
      <LinearProgress />
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={isDesktop ? true : openSidebar}
        variant={isDesktop ? "persistent" : "temporary"}
      />
      <main className={classes.content}>
        {children}
        <Footer />
      </main>
    </div>
  );
}

Main.propTypes = {
  children: PropTypes.node
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: "100%"
  }
}));

export default Main;
