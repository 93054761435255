class AlertHelper {
  constructor() {
    this.alerter = null;
  }

  registerAlert(alerter) {
    this.alerter = alerter;
  }

  confirm({ message, callback, className = null }) {
    if (this.alerter !== null) {
      this.alerter.confirm(message, callback, className);
    }
  }

  info({ message, duration = 7000 }) {
    if (this.alerter !== null) {
      this.alerter.alert(message, duration, "info");
    }
  }

  error({ message, duration = 7000 }) {
    if (this.alerter !== null) {
      this.alerter.alert(message, duration, "error");
    }
  }

  warning({ message, duration = 7000 }) {
    if (this.alerter !== null) {
      this.alerter.alert(message, duration, "warning");
    }
  }

  success({ message, duration = 7000 }) {
    if (this.alerter !== null) {
      this.alerter.alert(message, duration, "success");
    }
  }
}

export default new AlertHelper();
