import React, {useEffect, useState} from "react";
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from "react-redux";
import {fetchEventByAsync, fetchNotPaidEventByCompany} from "../../features/reservationSlice";
import EnhancedAgenda from "../enhanced/EnhancedAgenda";
import EnhancedTableToolbar from "../enhanced/EnhancedTableToolbar";
import {Paper} from "@mui/material";
import HourModal from "../calendar/hourModal/HourModal";

function NotPaidReservationList() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchResults = useSelector((state) => state.reservation.notPaidReservationList);

  const [openHourModal, setOpenHourModal] = useState(false);
  const [selected, setSelected] = useState(null);

  function handleEventClick(clickInfo) {
    if (!clickInfo.allDay && !clickInfo.event.allDay) {
      dispatch(fetchEventByAsync(clickInfo.event.id));
      setSelected(clickInfo.event.id);
      setOpenHourModal(true);
    }
  }

  useEffect(() => {
    dispatch(fetchNotPaidEventByCompany());
  }, [dispatch]);
    return (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <EnhancedTableToolbar authority="calendar.reservation-not-paid" label="Neuzavřené rezervace" />
            <EnhancedAgenda
                searchResults={searchResults}
                classes={classes}
                handleEventClick={handleEventClick}
                isAgendaView={true}
            />
            <HourModal open={openHourModal} setOpen={setOpenHourModal} selected={selected} setSelected={setSelected} />
          </Paper>
        </div>
    );

}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  }
}));

export default NotPaidReservationList;
