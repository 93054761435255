import React from "react";
import EnhancedButton from "./EnhancedButton";

function EnhancedCloseButton(props) {
  const { handleClose, disabledCancel = false } = props;

  return (
    <>
      <EnhancedButton
        onClick={handleClose}
        variant="contained"
        disabled={disabledCancel}
        sx={styles.cancel}
        label="Zavřít"
      />
    </>
  );
}

const styles = {
  cancel: {
    color: "black",
    background: "#D3D3D3",
    "&:hover": {
      color: "black",
      background: "#BEBEBE"
    }
  }
};

export default EnhancedCloseButton;
