import React from "react";
import { FormControl, TextField } from "@mui/material";

function EnhancedTextField(props) {
  const { name, value, onChange, label, maxLength, ...rest } = props;

  return (
    <FormControl variant="outlined" style={{width: "100%"}}>
      <TextField
        name={name}
        inputProps={{
          name: name,
          id: `outlined-${name}`,
          maxLength: maxLength
        }}
        value={value || ""}
        onChange={onChange}
        label={label}
        variant="outlined"
        {...rest}
      />
    </FormControl>
  );
}

export default EnhancedTextField;
