import { getExportFetchURL } from "../helpers/actionHelpers";

export function exportReceptionistCashReport(apiData) {
  window.open(getExportFetchURL(`/report/receptionist-cash/${apiData.companyId}/${apiData.dateSince}/${apiData.dateTo}/${apiData.userId}`));
}

export function exportCompanyCardReport(apiData) {
  window.open(getExportFetchURL(`/report/company-card/${apiData.companyId}/${apiData.dateSince}/${apiData.dateTo}`));
}

export function exportMasseursWorkSheetReport(apiData) {
  window.open(getExportFetchURL(`/report/masseur-work-sheet/${apiData.companyId}/${apiData.dateSince}/${apiData.dateTo}/${apiData.userId}`));
}

export function exportCompanySlevomatPaidReport(apiData) {
  window.open(getExportFetchURL(`/report/company-slevomat/${apiData.companyId}/${apiData.dateSince}/${apiData.dateTo}`));
}

export function exportCompanySlevomatApplyReport(apiData) {
  window.open(getExportFetchURL(`/report/company-slevomat-apply/${apiData.companyId}/${apiData.dateSince}/${apiData.dateTo}`));
}

export function exportCompaniesCashReport(apiData) {
  window.open(getExportFetchURL(`/report/companies-cash-payment/${apiData.companyId}/${apiData.dateSince}/${apiData.dateTo}`));
}

export function exportAttendanceListReport(apiData) {
  window.open(getExportFetchURL(`/pdf-file/attendance-list/${apiData.userId}/${apiData.dateSince}/${apiData.dateTo}`));
}
