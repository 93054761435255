import React from "react";
import { styled } from '@mui/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {Box, Container} from "@mui/material";
import 'moment/locale/cs';
import moment from "moment";

function EnhancedDateField(props) {
  const { name, label, disabled, value, handleChange, ...rest } = props;

  moment.locale('cs');
  
  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="cs">
        <StyledContainer components={['DatePicker']}>
          <StyledDatePicker
            name={name}
            value={value}
            label={label}
            views={['year', 'month', 'day']}
            onChange={handleChange}
            disabled={disabled}
            format="DD.MM. YYYY"
            autoOk
            {...rest}
          />
        </StyledContainer>
      </LocalizationProvider>
    </Box>
  );
}

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  width: "100%",
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingLeft: "0!important",
  paddingRight: "0!important"
}));

export default EnhancedDateField;
