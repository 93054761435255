import "@fortawesome/fontawesome-free/css/all.css";
import { FormControl, Grid, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import {  Search } from "@mui/icons-material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchFilteredPaymentsByBillUUID } from "../../features/paymentSlice";
import EnhancedIconButton from "../enhanced/EnhancedIconButton";

const PaymentSearchForm = (props) => {
  const dispatch = useDispatch();

  const { setPaymentView, paymentViewConstants, className } = props;

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = () => {
    dispatch(fetchFilteredPaymentsByBillUUID(searchValue));
    setPaymentView(paymentViewConstants.TABLE_VIEW);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") handleSearch();
  };

  return (
    <Grid item className={className}>
      <Grid item xs={6}>
        <FormControl onKeyDown={(e) => handleKeyDown(e)} fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-amount">Search</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <EnhancedIconButton ariaLabel="back icon" tooltip="Zpět" Icon={Search} onClick={handleSearch} />
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default PaymentSearchForm;
