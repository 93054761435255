class ReservationDateValidator {
  constructor() {
  }

  isDateStartValid(dateString) {
    let dateObj = new Date(dateString);
    return dateObj.getHours() >= 8 && dateObj.getHours() <= 22;
  }

  isDateEndValid(dateString) {
    let dateObj = new Date(dateString);
    return dateObj.getHours() > 8 && dateObj.getHours() <= 23;
  }
}

export default new ReservationDateValidator();
