import React from "react";
import { FormControl, InputLabel, Select } from "@mui/material";
import { makeStyles } from '@mui/styles';

function EnhancedSelect(props) {
  const classes = useStyles();
  const { name, value, onChange, label, list, itemId = "id", itemName = "name", ...rest } = props;

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel>{label}</InputLabel>
      <Select
        native
        value={value || ""}
        label={label}
        onChange={onChange}
        inputProps={{
          name: name,
          id: "outlined-" + name
        }}
        {...rest}>
        <option aria-label="None" value="" />
        {list.map((l) => (
          <option key={l[itemId]} value={l[itemId]}>
            {l[itemName]}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%"
    }
}));

export default EnhancedSelect;
