import React from "react";
import Button from '@mui/material/Button';
import GrantedAuthHelper from "../../helpers/GrantedAuthHelper";

function EnhancedButton(props) {
  const { label, onClick, disabled, className, authority, color, sx } = props;
  const calcDisabled = disabled || (authority && !GrantedAuthHelper.hasAuthority(authority));
  
  return (
    <>
      <Button
        onClick={onClick}
        disabled={calcDisabled}
        variant="contained"
        className={className}
        color={color}
        sx={sx}
      >
        {label}
      </Button>
    </>
  );
}

export default EnhancedButton;
