import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from "@mui/material";
import {Alert as MUiAlert} from "@mui/lab";
import EnhancedCloseButton from "./enhanced/EnhancedCloseButton";
import alertHelper from "../helpers/alertHelper";
import EnhancedConfirmButton from "./enhanced/EnhancedConfirmButton";

class Alert extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      alertMessage: "",
      confirmMessage: "",
      duration: 0,
      showAlert: false,
      showConfirm: false,
      severity: "",
      confirmCallback: () => {}
    };
  }

  componentDidMount() {
    alertHelper.registerAlert(this);
  }

  alert(message, duration, severity) {
    this.setState({
      alertMessage: message,
      duration,
      showAlert: true,
      severity
    });
  }

  confirm(message, confirmCallback, className) {
    this.setState({
      confirmMessage: message,
      showConfirm: true,
      confirmCallback,
      className: className
    });
  }

  handleAlertClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showAlert: false });
  }

  handleConfirmCancel() {
    this.setState({ showConfirm: false });
  }

  handleConfirmOk() {
    this.state.confirmCallback();
    this.setState({ showConfirm: false, confirmCallback: () => {} });
  }

  render() {
    const { showAlert, duration, severity, alertMessage, showConfirm, confirmMessage, className } = this.state;

    return (
      <>
        <Snackbar
            open={showAlert}
            autoHideDuration={duration}
            onClose={this.handleAlertClose.bind(this)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <MUiAlert onClose={this.handleAlertClose.bind(this)} severity={severity} sx={{ width: '100%' }}>
            {alertMessage}
          </MUiAlert>
        </Snackbar>
        <Dialog
          disableEscapeKeyDown
          maxWidth="xs"
          aria-labelledby="confirmation-dialog-title"
          open={showConfirm}>
          <DialogTitle id="confirmation-dialog-title">Dotaz</DialogTitle>
          <DialogContent dividers>
            <DialogContentText className={className}>{confirmMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <EnhancedCloseButton handleClose={this.handleConfirmCancel.bind(this)} />
            <EnhancedConfirmButton handleOk={this.handleConfirmOk.bind(this)} okLabel="Ano" />
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default Alert;
