import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import csLocale from "@fullcalendar/core/locales/cs";
import listPlugin from "@fullcalendar/list";
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from "react-redux";
import { fetchMasseursEvents } from "../../features/reservationSlice";
import EnhancedTableToolbar from "../enhanced/EnhancedTableToolbar";
import {getSessionCompanyId} from "../../helpers/sessionHelper";

function AgendaList() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const calendar = useSelector((state) => state.reservation.calendar);

  const [calendarEvents, setCalendarEvents] = useState(calendar);
  useEffect(() => {
    setCalendarEvents(calendar);
  }, [calendar]);

  useEffect(() => {
    getSessionCompanyId() && dispatch(fetchMasseursEvents());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <EnhancedTableToolbar label="Kalendář" />
      <FullCalendar
        plugins={[listPlugin]}
        headerToolbar={{
          left: "title",
          center: "",
          right: "today prev,next"
        }}
        initialView="listWeek"
        events={calendarEvents}
        expandRows={true}
        locale={csLocale}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
        fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
    }
}));

export default AgendaList;
