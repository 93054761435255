import React, {useEffect, useState} from "react";
import {DialogActions, Grid} from "@mui/material";
import Paper from "@mui/material/Paper";
import { makeStyles } from '@mui/styles';
import EnhancedTableToolbar from "../enhanced/EnhancedTableToolbar";
import {useDispatch} from "react-redux";
import EnhancedConfirmButton from "../enhanced/EnhancedConfirmButton";
import EnhancedDateField from "../enhanced/EnhancedDateField";
import EnhancedSelect from "../enhanced/EnhancedSelect";
import {fetchUserListByCompanyId} from "../../features/usersSlice";
import EnhancedButton from "../enhanced/EnhancedButton";
import {fetchUserAttendanceList} from "../../features/attendanceListSlice";
import {exportAttendanceListReport} from "../../features/report";

const AttendanceListForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userList, setEventView, eventViewConstants } = props;
  const [formState, setFormState] = useState({});

  useEffect(() => {
    dispatch(fetchUserListByCompanyId());
  }, [dispatch]);

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const handleChangeDateSince = (event) => {
    setFormState((formState) => ({
      ...formState,
      ['dateSince']: event
    }));
  };

  const handleChangeDateTo = (event) => {
    setFormState((formState) => ({
      ...formState,
      ['dateTo']: event
    }));
  };

  const handleGenerate = () => {
    const apiData = {
      userId: formState.employeeId,
      dateSince: formState.dateSince,
      dateTo: formState.dateTo
    };

    dispatch(exportAttendanceListReport(apiData));
  };

  const handleCheckAttendance = () => {
    let apiData = {
      userId: formState.employeeId,
      dateSince: formState.dateSince,
      dateTo: formState.dateTo
    };

    if (formState.employeeId === null) {

    }

    dispatch(fetchUserAttendanceList(apiData));

    setEventView(eventViewConstants.ATTENDANCE_TABLE_VIEW);
  };

  return (
      <Paper className={classes.paper}>
        <EnhancedTableToolbar label="Docházka" />
        <Grid container spacing={1}>
          <Grid item xs={6} className={classes.formControl}>
            <EnhancedDateField
                name="dateSince"
                value={formState.dateSince}
                onChange={handleChangeDateSince}
                label="Datum od"
            />
          </Grid>
          <Grid item xs={6} className={classes.formControl}>
            <EnhancedDateField
                name="dateTo"
                value={formState.dateTo}
                onChange={handleChangeDateTo}
                label="Datum do"
            />
          </Grid>
          <Grid item xs={12} className={classes.formControl}>
            <EnhancedSelect
                name="employeeId"
                value={formState.employeeId}
                label="Zaměstnanec"
                list={userList}
                itemName={"fullName"}
                onChange={handleChange}
            />
          </Grid>

          <DialogActions className={classes.buttonControl}>
            <Grid>
              <EnhancedConfirmButton handleOk={handleGenerate} okLabel="Vygenerovat report" />
            </Grid>
            <Grid>
              <EnhancedButton
                  onClick={handleCheckAttendance}
                  label="Zkontrolovat docházku"
                  authority="attendance.view"
                  sx={{
                    color: "white",
                    background: "blue",
                    "&:hover": {
                      color: "white",
                      background: "darkblue"
                    }
                  }}
              />
            </Grid>
          </DialogActions>
        </Grid>
      </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(4),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(10)
  },
  formControl: {
    width: "100%",
    padding: '5px'
  },
  buttonControl: {
    float: "left"
  },
}));

export default AttendanceListForm;
