import React from "react";
import { AppBar, Toolbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import {makeStyles, withStyles} from '@mui/styles';
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { getSessionCompanyName } from "../../../helpers/sessionHelper";

function Topbar() {
  const classes = useStyles();
  const headerLabel = getSessionCompanyName();

  return (
    <AppBar className={classes.root}>
      <Toolbar>
        <RouterLink to="/" className={classes.title}>
          <Typography variant="h2" component="h3" sx={titleStyle.title}>
            {headerLabel}
          </Typography>
        </RouterLink>
      </Toolbar>
    </AppBar>
  );

}

const titleStyle = {
  title: {
    color: "white",
    textDecoration: "none"
  }
}

Topbar.propTypes = {
  className: PropTypes.string
};

const useStyles = makeStyles(() => ({
  root: {
    background: '#8bc34a',
    boxShadow: "none"
  }
}));

export default Topbar;
