import React from "react";
import {Paper, Typography} from "@mui/material";
import {styled} from '@mui/styles';
import { useSelector } from "react-redux";
import EnhancedAgenda from "../enhanced/EnhancedAgenda";

const Agenda = (props) => {
  const searchResults = useSelector((state) => state.reservation.searchResults);
  const { className, handleEventClick, isAgendaView } = props;

  if (searchResults.length === 0 && isAgendaView) {
    return (
        <StyledPaper>
          <Typography variant="h4" gutterBottom>
            Nebyly nalezeny žádné výsledky
          </Typography>
        </StyledPaper>
    );
  }

  return (
      <EnhancedAgenda
          searchResults={searchResults}
          className={className}
          handleEventClick={handleEventClick}
          isAgendaView={isAgendaView}
      />
  );
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: "1em",
  marginTop: "1em",
  textAlign: "center",
  color: theme.palette.text.secondary
}));

export default Agenda;
