import React, { useEffect, useState } from "react";
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  fetchAclRoles,
  fetchAllowedCompanyList,
  fetchCompanyList,
  fetchMasseurs,
  fetchReceptionists,
  fetchRooms
} from "../features/listsSlice";
import { fetchNotExpiredPassLog} from "../features/passLogSlice";
import {
  fetchCleanerEvents,
  fetchEventsAsync,
  fetchMasseursEvents,
  fetchNotPaidEventByCompany
} from "../features/reservationSlice";
import { fetchUserListByCompanyId, fetchUserProfileTypes } from "../features/usersSlice";
import { fetchNotExpiredVoucherListByCompanyId} from "../features/voucherSlice";
import { fetchCompanySums } from "../features/companySumSlice";
import GrantedAuthHelper from "../helpers/GrantedAuthHelper";
import { fetchHppInfo } from "../features/settingsSlice";
import {
  getSessionCalendarSettings,
  getSessionRooms,
  setSessionCalendarSettings,
  setSessionCompanyName
} from "../helpers/sessionHelper";
import EnhancedConfirmButton from "./enhanced/EnhancedConfirmButton";
import {fetchMassageTypeList} from "../features/pricingSlice";
import EnhancedSelect from "./enhanced/EnhancedSelect";


function Settings(props) {
  const classes = useStyles();
  let location = useLocation();

  const { openSettings, setOpenSettings } = props;
  const dispatch = useDispatch();

  const allowedCompanyList = useSelector((state) => state.lists.allowedCompanyList);
  const roomList = useSelector((state) => state.lists.roomList);
  const isRoomListFetching = useSelector((state) => state.lists.isRoomListFetching);

  useEffect(() => {
    openSettings && dispatch(fetchAllowedCompanyList());
  }, [dispatch, openSettings]);

  const [formState, setFormState] = useState(getSessionCalendarSettings());
  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    if (formState.companyId && openSettings) dispatch(fetchRooms(formState.companyId));
  }, [formState.companyId, dispatch, openSettings]);

  const [isRoomFetching, setIsRoomFetching] = useState(false);
  useEffect(() => {
    setIsRoomFetching(isRoomListFetching);
  }, [roomList, isRoomListFetching]);

  function handleClose() {
    if (isCancelDisabled()) return;
    setIsRoomFetching(true);
    setFormState(getSessionCalendarSettings());
    setOpenSettings(false);
  }

  function handleOk() {
    setSessionCalendarSettings(formState);
    setSessionCompanyName(companyName);
    switch (location.pathname) {
      case "/calendar":
        if (Object.keys(getSessionRooms()).length !== 0) dispatch(fetchEventsAsync());
        break;
      case "/user-management":
        dispatch(fetchUserListByCompanyId());
        dispatch(fetchAclRoles());
        dispatch(fetchUserProfileTypes());
        break;
      case "/company-management":
        dispatch(fetchCompanyList());
        break;
      case "/voucher-management":
        dispatch(fetchNotExpiredVoucherListByCompanyId());
        break;
      case "/pass-log-management":
        dispatch(fetchNotExpiredPassLog());
        break;
      case "/agenda":
        dispatch(fetchMasseursEvents());
        break;
      case "/cleaner-agenda":
        dispatch(fetchCleanerEvents());
        break;
      case "/sum-management":
        dispatch(fetchCompanySums());
        break;
      case "/report-management":
        if (GrantedAuthHelper.hasAuthority("calendar.edit")) {
          dispatch(fetchMasseurs());
          dispatch(fetchReceptionists());
        }
        break;
      case "/adv-report-management":
        break;
      case "/pricing-management":
        dispatch(fetchMassageTypeList());
        break;
      case "/payment-management":
        break;
      case "/not-paid-reservations":
        dispatch(fetchNotPaidEventByCompany());
        break;
      case "/import-attendance":
        break;
      case "/attendance-list":
        break;
      case "/informace-o-prav-vztahu-zalozenem-na-hpp":
        dispatch(fetchHppInfo());
        break;
      default:
        break;
    }

    handleClose();
  }

  const handleCompanyChange = (e) => {
    e.persist();
    setIsRoomFetching(true);
    setCompanyName(allowedCompanyList.find((cL) => cL.companyId === +e.target.value)?.companyName);
    setFormState({ [e.target.name]: e.target.value });
  };

  const handleRoomsChange = (e) => {
    e.persist();
    setFormState((formState) => ({
      ...formState,
      [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value
    }));
  };

  const isOkDisabled = () => {
    let formValues = Object.values(formState);
    return formValues.indexOf(true) === -1;
  };

  const isCancelDisabled = () => {
    return Object.keys(getSessionRooms()).length === 0;
  };

  return (
      <div className={classes.root}>
        <Dialog
            open={openSettings}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="md">
          <DialogTitle id="form-dialog-title">Výběr organizace</DialogTitle>
          <DialogContent>
            <Grid container spacing={3} sx={{marginTop: -2}}>
              <Grid item xs={12}>
                <EnhancedSelect
                    name="companyId"
                    value={formState.companyId}
                    label="Organizace"
                    list={allowedCompanyList}
                    itemId="companyId"
                    itemName="companyName"
                    onChange={handleCompanyChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  {isRoomFetching ? (
                      <CircularProgress />
                  ) : (
                      roomList.map((r) => (
                          <FormControlLabel
                              key={r.id}
                              control={
                                <Checkbox checked={formState[r.id] || false} onChange={handleRoomsChange} name={String(r.id)} />
                              }
                              label={r.name}
                          />
                      ))
                  )}
                </FormGroup>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <EnhancedConfirmButton handleOk={handleOk} disabledOk={isOkDisabled()} />
          </DialogActions>
        </Dialog>
      </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}));

export default Settings;
