import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getFetchURL } from "../helpers/actionHelpers";
import { getSessionCompanyId } from "../helpers/sessionHelper";

const initialState = {
  filteredCustomersList: [],
  customer: []
};

export const createCustomer = createAsyncThunk("modalEdit/createCustomer", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/customer/insert")
  };

  const response = await axios(options);

  return response.data;
});

export const editCustomer = createAsyncThunk("modalEdit/editCustomer", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/customer/edit")
  };

  const response = await axios(options);

  return response.data;
});

export const fetchFilteredCustomers = createAsyncThunk(
  "modalEdit/fetchFilteredCustomers",
  async (filterText, { dispatch }) => {
    const response = await axios.get(getFetchURL(`/customer/find/${getSessionCompanyId()}/${filterText}`));

    return response.data;
  }
);

export const deleteCustomer = createAsyncThunk("modalEdit/deleteCustomer", async ({id, searchText}, { dispatch }) => {
  const options = {
    method: "DELETE",
    url: getFetchURL(`/customer/delete/${id}`)
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchFilteredCustomers(searchText));
  });

  return response.data;
});

export const modalEditSlice = createSlice({
  name: "modalEdit",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.filteredCustomersList.push(action.payload);
        state.customer = action.payload;
      })
      .addCase(editCustomer.fulfilled, (state, action) => {
        const indexOfElement = state.filteredCustomersList.findIndex(obj=>obj.id === action.payload.id);
        state.filteredCustomersList[indexOfElement] = action.payload;
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.filteredCustomersList = action.payload;
      })
      .addCase(fetchFilteredCustomers.fulfilled, (state, action) => {
        state.filteredCustomersList = action.payload;
      });
  }
});

export default modalEditSlice.reducer;
