import React from "react";
import MuiLinearProgress from "@mui/material/LinearProgress";
import { withStyles } from "@mui/styles";
import linearProgressHelper from "../helpers/linearProgressHelper";

class LinearProgress extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      visibility: false
    };
  }

  componentDidMount() {
    linearProgressHelper.registerLinearProgress(this);
  }

  componentWillUnmount() {
    linearProgressHelper.unregisterLinearProgress();
  }

  setVisibility(visibility) {
    this.setState({
      visibility: visibility
    });
  }

  render() {
    const { classes } = this.props;
    const { visibility } = this.state;

    function getVisibility()
    {
      var display = 'none';
      if (visibility) {
        display = 'block';
      }

      return {
        display: display
      }
    }

    return <MuiLinearProgress className={visibility ? "" : classes.dNone} sx={getVisibility} />;
  }
}

const styles = {
  dNone: {
    display: "none"
  }
};

export default withStyles(styles)(LinearProgress);
