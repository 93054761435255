import React from "react";
import { Link, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import Moment from "moment";

function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="body1">
        &copy;{" "}
        Nevidomí maséři s.r.o.
        2020 - {Moment().format("Y")}
      </Typography>
      <Typography variant="caption"></Typography>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    }
}));

export default Footer;
