import { Paper, TableCell } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Delete, Edit, List, PictureAsPdf } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EnhancedTable from "../enhanced/EnhancedTable";
import NewPassLogDialog from "./NewPasslogDialog";
import PassLogEditDialog from "./PasslogEditDialog";
import PassLogHistoryDialog from "./PasslogHistoryDialog";
import {deletePassLog, fetchExpiredPassLog, exportPassLogPdf, fetchNotExpiredPassLog} from "../../features/passLogSlice";
import alertHelper from "../../helpers/alertHelper";
import EnhancedPasslogTableToolbar from "../enhanced/EnhancedPasslogTableToolbar";
import EnhancedTableIconButton from "../enhanced/EnhancedTableIconButton";

function PassLogTable() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const passLogList = useSelector((state) => state.passLog.passLogList);

  const [open, setOpen] = useState(false);
  const [selectedPassLog, setSelectedPassLog] = useState({});
  const [openNew, setOpenNew] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [formState, setFormState] = useState({});

  useEffect(() => {
      if (formState?.expiredPasslogs) {
          dispatch(fetchExpiredPassLog());
      } else {
          dispatch(fetchNotExpiredPassLog());
      }
  }, [dispatch, formState]);

  const headerList = [
    { align: "left", label: "" },
    { align: "right", label: "ID" },
    { align: "right", label: "Jméno" },
    { align: "right", label: "Číslo permanentky" },
    { align: "right", label: "Datum platnosti" },
    { align: "right", label: "Vytvořil/a" },
    { align: "right", label: "Vytvořeno dne" },
    { align: "right", label: "Číslo účtenky" },
    { align: "right", label: "Využito" },
    { align: "right", label: "Poznámka" },
  ];

    const handleChange = (e) => {
        e.persist();
        setFormState((formState) => ({
            ...formState,
            [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value
        }));
    };

  const handleEdit = (passlog) => {
    setSelectedPassLog(passlog);
    setOpen(true);
  };

  const handleDelete = (passlog) => {
    alertHelper.confirm({
      message: "Opravdu si přejete smazat permanentku?",
      callback: () => dispatch(deletePassLog(passlog.id))
    });
  };

  const showPassLogHistory = (passlog) => {
    setSelectedPassLog(passlog);
    setOpenHistory(true);
  };

    function handleExportPassLog(passlog) {
        if (passlog.id) {
            dispatch(exportPassLogPdf(passlog.id));
        }
    }

    const styles = {
        fontSize: {
         fontSize: "14px"
        }
    }

  const renderRowCells = (passlog) => (
    <>
      <TableCell>
        <EnhancedTableIconButton
          authority="passlog.view"
          ariaLabel="history row"
          tooltip="Historie návštev"
          size="small"
          Icon={List}
          onClick={() => showPassLogHistory(passlog)}
        />
        <EnhancedTableIconButton
          authority="passlog.edit"
          ariaLabel="pdf row"
          tooltip="Vygenerovat permanentku"
          size="small"
          Icon={PictureAsPdf}
          onClick={() => handleExportPassLog(passlog)}
          disabled={passlog.isAnonymizeVoucherNumber}
        />
        <EnhancedTableIconButton
          authority="passlog.edit"
          ariaLabel="edit row"
          tooltip="Upravit"
          size="small"
          Icon={Edit}
          onClick={() => handleEdit(passlog)}
        />
        <EnhancedTableIconButton
          authority="passlog.delete"
          ariaLabel="remove row"
          tooltip="Smazat"
          size="small"
          Icon={Delete}
          disabled={!passlog.deletable}
          onClick={() => handleDelete(passlog)}
        />
      </TableCell>
      <TableCell align="right" sx={styles.fontSize}>{passlog["id"]}</TableCell>
      <TableCell align="right" sx={styles.fontSize}>{passlog["title"]}</TableCell>
      <TableCell align="right" sx={styles.fontSize}>{passlog["numberVisible"]}</TableCell>
      <TableCell align="right" sx={styles.fontSize}>{passlog["afterExpire"] ? "Neplatný" : passlog["dateExpireText"]}</TableCell>
      <TableCell align="right" sx={styles.fontSize}>{passlog["createdBy"]}</TableCell>
      <TableCell align="right" sx={styles.fontSize}>{passlog["created"]}</TableCell>
      <TableCell align="right" sx={styles.fontSize}>{passlog["billUUID"]}</TableCell>
      <TableCell align="right" sx={styles.fontSize}>{passlog["used"]} / 11</TableCell>
      <TableCell align="right" sx={styles.fontSize}>{passlog["noteShort"]}</TableCell>
    </>
  );

  return (
    <div className={classes.root}>
        <Paper className={classes.paper}>
            <EnhancedPasslogTableToolbar
                label="Permanentky"
                setOpenNew={setOpenNew}
                onChange={handleChange}
            />
            <EnhancedTable headerList={headerList} dataList={passLogList} renderRowCells={renderRowCells} />
        </Paper>
      <PassLogEditDialog open={open} passlog={selectedPassLog} setOpen={setOpen} />
      <NewPassLogDialog open={openNew} setOpenNew={setOpenNew} />
      <PassLogHistoryDialog open={openHistory} setOpenHistory={setOpenHistory} passlog={selectedPassLog} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  iconCell: {
    whiteSpace: "nowrap"
  },

}));

export default PassLogTable;
