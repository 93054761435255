import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from "react";
import EnhancedConfirmButton from "../enhanced/EnhancedConfirmButton";
import EnhancedDialogTitle from "../enhanced/EnhancedDialogTitle";
import {
  editBill,
  fetchCalendarMassagePrices,
  fetchPassLogPrices,
  fetchPaymentTypes,
  fetchVoucherPrices
} from "../../features/paymentSlice";
import {useDispatch, useSelector} from "react-redux";
import {getSessionCheckedCompany} from "../../helpers/sessionHelper";
import Moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import csLocale from "date-fns/locale/cs";
import PaymentTypeResolver from "../../helpers/paymentTypeResolver";
import EnhancedTicketPayment from "../enhanced/EnhancedTicketPayment";
import {TextField} from "@mui/material";
import EnhancedDateField from "../enhanced/EnhancedDateField";

function PaymentEditDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { open, setOpen, payment } = props;
  const [formState, setFormState] = useState({});
  const typePriceOfMassageList = useSelector((state) => state.payment.typePriceOfMassageList);

  useEffect(() => {
    if (open && payment) {
      setFormState((formState) => ({
        ...formState,
        id: payment?.id,
        paymentTypeId: payment?.paymentType?.id,
        billNumber: payment?.billUUID,
        typeOfMassageId: payment?.massageType?.id,
        invoiceAddress: payment?.invoiceAddress,
        created: new Moment(payment?.created),
        massageTypeShort: payment?.massageType?.short,
        length: payment?.massageType?.length
      }));
    }
  }, [payment, open, dispatch]);

  useEffect(() => {
    if (open && payment) {
      dispatch(fetchPaymentTypes());

      if (formState.paymentTypeId) {
        if (PaymentTypeResolver.isPassLog(formState.massageTypeShort)) {
          dispatch(fetchPassLogPrices(formState.paymentTypeId));
        } else if (PaymentTypeResolver.isVoucher(formState.massageTypeShort)) {
          dispatch(fetchVoucherPrices(formState.paymentTypeId));
        } else if (
            PaymentTypeResolver.isCash(formState.paymentTypeId) || PaymentTypeResolver.isCreditCard(formState.paymentTypeId)
        ) {
          dispatch(fetchCalendarMassagePrices({
            paymentTypeId: formState.paymentTypeId,
            length: formState.length
          }));
        } else {
          alertHelper.error({
            message: 'Typ platby není podporovaný!',
            duration: 7000
          });
        }

      }
    }
  }, [open, payment, dispatch, formState]);

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  function setStartEndAtWorkAfterChange(date) {
    setFormState((formState) => ({
      ...formState,
      ['created']: date
    }));
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    const apiData = {};
    let createdDateTime = new Moment(formState.created);

    apiData.companyId = getSessionCheckedCompany();
    apiData.id = payment.id;
    apiData.paymentTypeId = formState.paymentTypeId;
    apiData.massageTypeId = formState.typeOfMassageId;
    apiData.invoiceAddress = formState.invoiceAddress;
    apiData.created = createdDateTime.format('YYYY-MM-DD HH:mm:ss');

    dispatch(editBill(apiData));
    handleClose();
  };

  return (
      <div className={classes.root}>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <EnhancedDialogTitle handleClose={handleClose} nameLabel="Úprava platby" />
          <DialogContent>
            <Grid container spacing={3} sx={{marginTop: -2}}>
              <EnhancedTicketPayment
                  formState={formState}
                  open={open}
                  disabled={false}
                  handleChange={handleChange}
                  typePriceOfMassageList={typePriceOfMassageList}
              />
              <Grid item xs={12}>
                <EnhancedDateField
                    name="created"
                    value={formState.created}
                    onChange={setStartEndAtWorkAfterChange}
                    label="Vytvořeno dne"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <EnhancedConfirmButton handleOk={handleOk} okLabel="Uložit" />
          </DialogActions>
        </Dialog>
      </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
}));

export default PaymentEditDialog;
