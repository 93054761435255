import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getFetchURL } from "../helpers/actionHelpers";
const initialState = {
  companySumList: []
};

export const fetchCompanySums = createAsyncThunk("companySum/fetchCompanySums", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/company-sum/get`));
  return response.data;
});

export const insertCompanySums = createAsyncThunk("companySum/insertCompanySums", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: {"Content-Type": "application/json"},
    url: getFetchURL("/company-sum/add")
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchCompanySums());
  });

  return response.data;
});

export const editCompanySum = createAsyncThunk("companySum/editCompanySum", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: {"Content-Type": "application/json"},
    url: getFetchURL("/company-sum/edit")
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchCompanySums());
  });

  return response.data;
});

export const companySumSlice = createSlice({
  name: "companySum",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanySums.fulfilled, (state, action) => {
        state.companySumList = action.payload;
      })
  }
});

export default companySumSlice.reducer;
