class UserRoleHelper {
  constructor() {
    this.roleName = '';
  }

  load(roleName) {
    this.roleName = roleName;
  }

  empty() {
    this.roleName = '';
  }

  isReceptionist() {
    return this.roleName === 'Recepční';
  }

  isSpravce() {
    return this.roleName === 'Správce';
  }

  isAdmin() {
    return this.roleName === 'Admin';
  }
}

export default new UserRoleHelper();
